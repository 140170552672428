
import React from "react";
import {
  Grid,
  Text,
  GridItem,
} from '@chakra-ui/react';

export default function Resultado({ ...props }) {
  const { data } = props

  return (
    <Grid
      borderRadius="xl"
      bg="linear-gradient(212deg, rgba(245,245,247,1) 32%, rgba(247,255,213,1) 100%)"
      m="auto"
      p="5%"
    >
      <GridItem w="70%" m="auto" pb="8%">
        <Text fontSize="16px" fontWeight="bold" color="#A5C918" textAlign="center">
          Te presentamos tu plan personalizado
        </Text>
      </GridItem>

      <GridItem py="8%">
        <Text fontSize="12px" fontWeight="bold">
          RESUMEN
        </Text>
        {data.pdvTerminals ?
          <div>
            <Text as="span" fontSize="12px" fontWeight="bold" pr="14px">
              Punto de venta:
            </Text>
            <Text as="span" fontSize="12px">
              {data.pdvTerminals}
            </Text>
          </div>
          : ""
        }
        {data.amountCompany ?
          <div>
            <Text as="span" fontSize="12px" fontWeight="bold" pr="14px">
              Cantidad de empresas:
            </Text>
            <Text as="span" fontSize="12px" >
              {data.amountCompany}
            </Text>
          </div>
          : ""
        }
        {data.amountUsers ?
          <div>
            <Text as="span" fontSize="12px" fontWeight="bold" pr="14px">
              Cantidad de Usuarios:
            </Text>
            <Text as="span" fontSize="12px" >
              {data.amountUsers}
            </Text>
          </div>
          : ""
        }
        {data.DTECompanies ?
          <div>
            <Text as="span" fontSize="12px" fontWeight="bold" pr="14px">
              Empresas con Gestión DTE:
            </Text>
            <Text as="span" fontSize="12px" >
              {data.DTECompanies}
            </Text>
          </div>
          : ""
        }
        {data.LCECompanies ?
          <div>
            <Text as="span" fontSize="12px" fontWeight="bold" pr="14px">
              Empresas con LCE:
            </Text>
            <Text as="span" fontSize="12px">
              {data.LCECompanies}
            </Text>
          </div>
          : ""
        }
        {data.usersTrib ?
          <div>
            <Text as="span" fontSize="12px" fontWeight="bold" pr="14px">
              Usuarios con Tributario Avanzado:
            </Text>
            <Text as="span" fontSize="12px">
              {data.usersTrib}
            </Text>
          </div>
          : ""
        }
      </GridItem>

      <GridItem>
        <Text fontSize="12px" fontWeight="bold" color="#A5C918">
          {data.dsct * 100}% descuento
        </Text>
        <Text fontSize="12px" color="#A5C918">
          Aplicado
        </Text>
      </GridItem>

      <GridItem py="3%" >
        <Grid float="right">
          <Text fontSize="12px" fontWeight="bold" color="#A5C918" right={0}>
            TARIFA FINAL
          </Text>
          <Text fontSize="30px" fontWeight="bold" color="#A5C918">
            {Math.round(data.finalRate * 100) / 100} UF
          </Text>
        </Grid>
      </GridItem>
    </Grid>
  );
}
