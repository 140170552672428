import React from "react";
import {
  Grid,
  Text,
  Image,
  Box,
} from '@chakra-ui/react';
import lottie from "lottie-web";

import listo02 from "../../administracion/jsons_images/Simular/listo02.svg"
import check from "../../administracion/jsons_images/Simular/animations/check.json"

export default function Listo() {

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#check-an"),
      animationData: check
    });
  }, []);

  return (
    <>
      <Grid
        w={{ md: "35%", base: "80%" }}
        m="auto"
        pt={{ md: "5%", base: "25%" }}
        pb="10%"
      >
        <Grid position="absolute" mt="140px" ml="-100px">
          <Image src={listo02} w="80%" alt="listo02" />
        </Grid>
        <Grid
          borderWidth="2px"
          borderColor={"#BEE625"}
          borderRadius={'xl'}
          bg="white"
        >
          <Box
            textAlign="center"
            py="5%"
            px="5%"
          >
            <Grid textAlign="center" w="80%" m="auto">
              <Text fontSize="30px" fontWeight="bold" color="#A5C918">¡Listo!</Text>
              <Text fontSize="12px"  >Hemos enviado tu cotización a uno de nuestros ejecutivos y una copia a tu correo</Text>
              <Grid m="auto">
                <div id="check-an" style={{ width: 150, height: 150 }} />
              </Grid>
              <Text fontSize="16px" fontWeight="bold">En breves momentos </Text>
              <Text fontSize="16px" fontWeight="bold">Te contactaremos </Text>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
