import {
  Grid,
} from '@chakra-ui/react';
import React from "react";

import Conocenos from "../../components/Nosotros/Conocenos";


export default function Nosotros() {

  return (
    <Grid >
      <Conocenos />
      {/* <Equipo /> */}
    </Grid >
  );
}
