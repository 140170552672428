import bg_m from './erp_m_bg.png'
import bg_w from './erp_w_bg.png'

import mod01 from './Modulos/mod01.svg'
import mod02 from './Modulos/mod02.svg'
import mod03 from './Modulos/mod03.svg'
import mod04 from './Modulos/mod04.svg'
import mod05 from './Modulos/mod05.svg'
import mod06 from './Modulos/mod06.svg'
import mod07 from './Modulos/mod07.svg'

import modesp01 from './ModulosEspeciales/modesp01.png'
import modesp02 from './ModulosEspeciales/modesp02.png'
import modesp03 from './ModulosEspeciales/modesp03.png'
import modesp04 from './ModulosEspeciales/modesp04.png'

const ERP = {
  bg_w: bg_w,
  bg_m: bg_m,
  titulo1: 'EL ERP que',
  titulo2: 'estabas',
  titulo3: 'buscando',
  subtitulo:
    'La mejor y mas completa herramienta para administrar todos los procesos de tu empresa; Compras, Ventas, Finanzas, Activo Fijo, Contabilidad, Impuestos mensuales, emisión de documentos, cumple con las obligaciones tributarias, genera tus liquidaciones de sueldo y visualiza informes estratégicos.',
  modulos: {
    titulo1: 'Algunos de sus',
    titulo2: 'módulos',
    contenido: [
      {
        titulo: 'Contabilidad',
        texto:
          'El módulo de contabilidad permite a los usuarios definir diferentes centros de costos asignando cuentas de ingresos y gastos a un centro de costo pre definido, permite reflejar los estados financieros en las monedas establecidas, ajustes automáticos de saldos y visualizar los principales informes.',
        imagen: mod01,
      },
      {
        titulo: 'Ventas',
        texto:
          'El módulo de ventas opera con conceptos que deben ser asociados a una cuenta contable. Según el concepto, el sistema solicita ingresar detalle de analíticos o Unidades de Negocio al momento de registrar un documento de venta. Proporciona libros e informes de analíticos y de centralización.',
        imagen: mod02,
      },
      {
        titulo: 'Adquisiciones',
        texto:
          'Facilita el uso del módulo, ya que está diseñado para usuarios que no son contadores, utilizando conceptos de compras para el registro de los documentos. La trazabilidad es una cualidad importante en nuestro sistema contable, ya que puede ir desde el Balance hasta dato que da origen al registro.',
        imagen: mod03,
      },
      {
        titulo: 'Tesorería',
        texto:
          'El módulo de tesorería permite llevar el movimiento diario de las cuentas de banco, permitiendo generar la conciliación bancaria, proporcionando información con las diferencias encontradas. Además se pueden configurar los formatos bancarios para cargar pagos con nóminas.',
        imagen: mod04,
      },
      {
        titulo: 'Activo fijo',
        texto:
          'El módulo de activo fijo permite efectuar un adecuado registro asociado a altas y baja de bienes, efectuando los cálculos en el proceso de corrección monetaria y depreciación, además de efectuar la centralización contable de manera automática.',
        imagen: mod05,
      },
      {
        titulo: 'Impuestos',
        texto:
          'El módulo de impuestos le permite controlar y cumplir adecuadamente con las obligaciones tributarias de una manera fácil e integrada, generando los formularios F-29, F-50 y declaración jurada de sueldos y honorarios entre otros.',
        imagen: mod06,
      },
      {
        titulo: 'Recursos humanos',
        texto:
          'El módulo de recursos humanos proporcionan posibilidades de gestión de personal. Genera contratos y liquidaciones de sueldos mediante pago con nomina, gestiona gratificaciones, bonos, créditos, prestamos aguinaldos y otros. Lleva el control de vacaciones, descuentos previsionales, finiquitos. Además Logra la centralización de sueldos.',
        imagen: mod07,
      },
    ],
  },
  modulosEspeciales: {
    titulo1: 'Nuestros',
    titulo2: 'módulos',
    titulo3: 'especiales para el ERP',
    subtitulo:
      'Con el paso del tiempo hemos desarrollado diferentes módulos que puedes integrar al ERP dependiendo de tus necesidades',
    contenido: [
      {
        titulo: 'Modulo de Berrys',
        texto:
          'Con el modulo de berrys puedes lograr el control de la cosecha y filtrando por temporero, huerto y variedad, consulta por la integración de este sistema en tu proyecto.',
        imagen: modesp01,
      },
      {
        titulo: 'Modulo de Áridos',
        texto:
          'Si tu rubro es el explotación de Áridos, con este modulo puedes controlar la salida de material, asignación de trabajo por vehículo de transporte y facturación de clientes.',
        imagen: modesp02,
      },
      {
        titulo: 'Modulo de Flota',
        texto:
          'Si tu rubro es el transporte de carga por carretera, tenemos un modulo con el que puedes controlar tus traslados a través de una hoja de ruta, controlar las mantenciones de tus camiones, llevar el control del consumo de combustible y gastos de traslados, pago de comisión a conductores y facturación al cliente.',
        imagen: modesp03,
      },
      {
        titulo: 'Modulo de Taller',
        texto:
          'Si te dedicas a la reparación y mantención de vehículos, con este modulo puedes generar un presupuesto de mantención, generar ordenes de trabajo, mantener el historial de mantenciones de vehículos entre otras muchas funciones.',
        imagen: modesp04,
      },
    ],
  },
}

export default ERP
