import erp_logo from './erp_logo.png'
import pdv_logo from './pdv_logo.png'
import trib_logo from './trib_logo.png'
import cont_logo from './cont_logo.png'
import bg_m_herramientas from './bg_m_herramientas.png'
import bg_w_herramientas from './bg_w_herramientas.png'

const herramientas = {
  bg_m_herramientas: bg_m_herramientas,
  bg_w_herramientas: bg_w_herramientas,
  titulo: 'Logra el control de tu empresa',
  subtitulo1: '¡Ponte al día!',
  subtitulo2:
    'No te quedes atrás, conoce las mejores herramientas para controlar tu empresa en la era digital.',
  ERP: {
    logo: erp_logo,
    title: 'ERP',
    descripcion:
      'Logra administrar todos los procesos de tu empresa, controla compras y ventas, contabilidad, activos, genera liquidaciones de sueldo, emite documentos, controla tus obligaciones tributarias, además mejora tu capacidad de decisión con informesestratégicos.',
  },
  PDV: {
    logo: pdv_logo,
    title: 'Punto de venta',
    descripcion:
      'Controla el inventario de tu empresa, emite documentos, optimiza tu cobranza, visualizainformes de stock valorizado, margen por área de negocio, top de ventas entre otros,todo en tiempo real.',
  },
  Tributario: {
    logo: trib_logo,
    title: 'Tributario',
    descripcion:
      'Con nuestra solución para contadores, lleva el control de tus registros, logra mantenerte al día con tus obligaciones tributarias emite documentos genera liquidaciones de sueldo y mejora la calidad de tus asesorías con informes estratégicos efectivos.',
  },
  Contador: {
    logo: cont_logo,
    title: 'Contador',
    descripcion:
      'Controla y cumple con las obligaciones tributarias con el único software que se mantiene a la vanguardia de las actualizaciones tributarias, operación IVA, operación Renta y Declaraciones Juradas. ¡ se integra a la perfección con ERP o Contador!',
  },
}

export default herramientas
