import image from './excited-young-woman.jpg'

export const modulosDeclaraciones = {
    "titulo1": 'Módulos Del Sistema',
    "titulo2": 'Declaraciones Juradas',
    "subtitulo1": 'Caracteristicas',
    "imagen": image,
    "caracteristicas": ['CON LA RENTA LIQUIDA IMPONIBLE SE INGRESA AUTOMÁTICAMENTE TU DDJJ 1926', 'LLENADO AUTOMÁTICO DE LA DDJJ1948', 'LECTURA AUTOMÁTICA DE DDJJ EN EXCEL'],
    'modulos': [
        {
            "title": 'Régimen 14A',
            "list": ['DJ de Balance (1847)', 'DJ RLI (1926)', 'DJ de Retiros (1948)', 'DJ de Gastos Rechazados (1909)'],
        },
        {
            "title": 'Régimen 14 D3',
            "list": ['DJ de Retiros (1948)', 'DJ de Gastos Rechazados (1909)'],
        },
        {
            "title": 'Régimen 14 D8',
            "list": ['DJ Régimen de Transparencia (1947)'],
        },
        {
            "title": 'Declaraciones Juradas transversales',
            "list": ['DJ de Sueldos (1887)', 'DJ de Honorarios (1879)'],
        },
    ],
}