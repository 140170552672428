import React, { useState } from 'react';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';
import slider from '../../../administracion/jsons_images/Productos/Contador/Slider/slider';
import './slider.css';
import StyleText from '../../../utils/styleText';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const [slideIndex, setSlideIndex] = useState(1);
  const timeoutRef = React.useRef(null);
  const navigate = useNavigate();
  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setSlideIndex(prevIndex =>
          prevIndex === slider.length ? 1 : prevIndex + 1
        ),
      8000
    );
    return () => {
      resetTimeout();
    };
  }, [slideIndex]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  // const handleChangePage = link => {
  //   history.push(link);
  // };

  const moveDot = index => {
    setSlideIndex(index);
  };

  // navigation redirect to another page
  // const navigateTo = link => {
  //   // if link is not empty
  //   if (link !== '') {
  //     navigate('/webinar');
  //   }
  // };

  function SliderComponent({ slide }) {
    return (
      <Grid
        // redirect onclick
        // onClick={() => (slide.url ? navigateTo(slide.url) : null)}
        // show pointer cursor
        // cursor={slide.url ? 'pointer' : 'default'}
        bgImage={{
          base: slide.bg_m,
          sm: slide.bg_w,
        }}
        h={{ base: '100vh', sm: '100vh', md: '100vh', lg: '80vh' }}
        bgSize={{
          base: 'cover',
          sm: 'cover',
          md: 'cover',
          lg: 'cover',
          xl: 'cover',
        }}
        backgroundRepeat="no-repeat"
      >
        <GridItem
          px={{ base: '3%', lg: '5%' }}
          pt={{ base: '100px', sm: '150px', lg: '150px' }}
        >
          <Grid w={{ base: '100%', lg: '60%', xl: '55%' }}>
            <Text
              fontSize={{ base: '3xl', sm: '3xl', md: '4xl', lg: '4xl' }}
              fontWeight="bold"
              pl="10px"
            >
              {/* {slide.titulo1} */}

              <StyleText
                text={slide.titulo}
                keyWords={slide.keyWordsTitulo}
                background={true}
              />
            </Text>
          </Grid>
          <Text
            // sm w="100%" md w="100%" lg w="100%" xl in chakra ui
            w={{ base: '100%', sm: '50%', md: '50%', lg: '50%', xl: '50%' }}
            // w="50%"
            // fontSize="3xl"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '3xl' }}
            pl="10px"
          // noOfLines={2}
          >
            <StyleText
              text={slide.subtitulo1}
              keyWords={slide.keyWordsSubtitulo}
              background={false}
            />
          </Text>

          <Grid w={{ base: '100%', lg: '60%', xl: '45%' }}>
            <Text fontSize="16px" pl="10px">
              {parse(slide.subtitulo2)}
            </Text>
          </Grid>
          <Grid
            pt={slide.subtitulo3 ? 5 : null}
            w={{ base: '80%', lg: '60%', xl: '45%' }}
          >
            {/* word spacing */}
            <Text
              fontSize="20px"
              // fontWeight={'bold'}
              letterSpacing={-1.5}
              pl="10px"
            >
              {parse(slide.subtitulo3)}
            </Text>
          </Grid>
          <Grid pt={5} w={{ base: '80%', lg: '60%', xl: '45%' }}>
            {/* word spacing */}
            <Text
              fontSize="20px"
              // fontWeight={'bold'}
              letterSpacing={-1.5}
              pl="10px"
            >
              {/* create a list with items array */}
              {slide.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </Text>
          </Grid>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Grid id="Inicio" className="container-slider">
      <SliderComponent slide={slider[slideIndex - 1]} key={slideIndex} />

      <div
        // container dots on windows mode and flex on mobile mode

        // className="container-dots"
        // inline style
        // display flex
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '10px',
          width: '100%',
        }}
      >
        {slider.map((_, index) => (
          <div
            key={index}
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? 'dot active' : 'dot'}
          ></div>
        ))}
      </div>
    </Grid>
  );
}
