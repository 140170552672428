import iconoPaso01 from './iconoPaso01.png';
import iconoPaso02 from './iconoPaso02.png';
import iconoPaso03 from './iconoPaso03.png';
import iconoPaso04 from './iconoPaso04.png';
import iconoPaso05 from './iconoPaso05.png';
import iconoPaso06 from './iconoPaso06.png';



const pasos = [
    {
        imagen: iconoPaso01,
        titulo: 'Cargas Masivas',
        descripcion: 'Importa masivamente desde Excel el libro mayor, plan de cuentas o bien, el balance.'
    },
    {
        imagen: iconoPaso02,
        titulo: 'Regimenes Tributarios 14 A 14 D3 14D8',
        descripcion: 'Automatiza el proceso de renta liquida imponible, rentas empresariales, capital propio, acciones y creditos. Obten fácil el Ppua, ddan, sac, stut, rex, rai.'
    },
    {
        imagen: iconoPaso03,
        titulo: 'Proyeccion de renta',
        descripcion: 'Proyecta automáticamente la renta y preparate para anticiparte a cualquier escenario.'
    },
    {
        imagen: iconoPaso04,
        titulo: 'Declaraciones Juradas',
        descripcion: 'Sube al SII declaraciones y certificados  completamente automatizados. Además, si tienes el sistema contable esta información se traspará automáticamente!'
    },
    {
        imagen: iconoPaso05,
        titulo: 'Formulario 22',
        descripcion: 'automatiza la declaración de impuesto a la renta, declaraciones juradas y regimenes tributarios.'
    },
    {
        imagen: iconoPaso06,
        titulo: 'Multi empresas',
        descripcion: 'el plan básico permite un usuario y empreas ilimitadas, agrega usuarios según necesites!'
    }
];

export default pasos;

