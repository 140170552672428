import React from "react";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import acomodamos from '../../administracion/jsons_images/Home/Acomodamos/acomodamos.json'

export default function Acomodamos() {
  return (
    <Grid
      templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(3, 1fr)" }}
      pt={{ lg: "25px", base: "25px" }}
      px={{ lg: "15%", base: "10%" }}
      gap={{ lg: 1, base: 10 }}
    >
      <GridItem
        ml={{ base: "10%", lg: "0%" }}
      >
        <Box >
          <Text fontSize="30px" fontWeight={"bold"}>
            {acomodamos.titulo1}
          </Text>
          <Text fontSize="30px" fontWeight={"bold"} as="mark" backgroundColor="#BEE625">
            {acomodamos.titulo2}
          </Text>
          <Text as="span" fontSize="30px" fontWeight={'bold'}>
            {" "}{acomodamos.titulo3}
          </Text>
        </Box>
      </GridItem>
      <GridItem
        w={{ lg: "55%", base: "90%" }}
        ml={{ lg: "25%", base: "5%" }}
        py="3%"
      >
        <Box>
          <Text fontSize="16px" fontWeight={"bold"} textAlign={{ lg: "left", base: "center" }}>
            {acomodamos.preguntas.pregunta1.pregunta}
          </Text>
          <Text fontSize="12px" textAlign={{ lg: "left", base: "center" }}>
            {parse(acomodamos.preguntas.pregunta1.respuesta)}
          </Text>
        </Box>
      </GridItem>
      <GridItem
        w={{ lg: "55%", base: "90%" }}
        marginLeft={{ lg: "5%", base: "5%" }}
        py="3%"
      >
        <Box>
          <Text fontSize="16px" fontWeight={'bold'} textAlign={{ lg: "left", base: "center" }}>
            {acomodamos.preguntas.pregunta2.pregunta}
          </Text>
          <Text fontSize="12px" textAlign={{ lg: "left", base: "center" }}>
            {parse(acomodamos.preguntas.pregunta2.respuesta)}
          </Text>
          <Grid pt="15px" w={{ base: "50%", lg: "100%" }} m="auto">
            <Button bgColor="#A5C918" color="white" borderRadius="17px" >
              Contáctanos
            </Button>
          </Grid>
        </Box>
      </GridItem>
    </Grid>
  );
}
