// IMAGENES BACKGROUND

import bg0_w from './slide0_w_bg.jpg'
import bg0_m from './slide0_m_bg.jpg'
import bg1_m from './slide1_m_bg.jpg'
import bg1_w from './slide1_w_bg.jpg'
import bg2_m from './slide2_m_bg.jpg'
import bg2_w from './slide2_w_bg.jpg'
import bg3_m from './slide3_m_bg.jpg'
import bg3_w from './slide3_w_bg.jpg'
import bg4_m from './slide4_m_bg.jpg'
import bg4_w from './slide4_w_bg.jpg'

// VIDEOS
// import video1 from './audisoft3.mov'
// import video2 from './audisoft4.mov'
// import video3 from './audisoft5.mov'
// import video4 from './audisoft6.mov'

// get url
const url = window.location.href

const slider = [
  {
    bg_m: bg1_m,
    bg_w: bg1_w,
    titulo: 'Los más <br/> Experimentados',
    subtitulo1: 'Jamás descuidan el cumplimiento tributario',
    subtitulo2: 'Controla y cumple con las obligaciones tributarías con el único software que se mantiene a la vanguardia de las actualizaciones tributarias, operación IVA,\n operacion Renta y Declaraciónes Juradas.',
    subtitulo3: 'Software autorizado por el SII para enviar declaraciones hace mas de 13 años',
    keyWordsTitulo: ['Los ', 'más'],
    keyWordsSubtitulo: [],
    items: [],
    // video: video1,
  },
  {
    bg_m: bg2_m,
    bg_w: bg2_w,
    titulo: 'TU TIEMPO ES LO MAS VALIOSO, <br/> OCUPALO EN LO QUE IMPORTA',
    subtitulo1: 'DEJA QUE NUESTRO SOFTWARE AUTOMATICE EL PROCESO DE RENTA',
    subtitulo2: '',
    subtitulo3: '',
    keyWordsTitulo: ['VALIOSO'],
    keyWordsSubtitulo: ['AUTOMATICE'],
    items: ['Proceso de declaraciones juradas automatizado', 'Registros tributarios de rentas empresariales automático', 'Formulario 22 autocompletado', 'Proyección de renta', 'Traspaso de saldos financieros y tributarios', 'Automatiza el proceso de rentas empresariales'],
    // video: video2,
  },
  {
    bg_m: bg3_m,
    bg_w: bg3_w,
    titulo: '¿Que beneficios puedes obtener si también utilizas nuestro sistema contable ?',
    subtitulo1: 'En el caso de que trabajes integrado con el sistema contable, también podrás automatizar los siguientes procesos.',
    subtitulo2: '',
    subtitulo3: '',
    keyWordsTitulo: ['beneficios', 'sistema ', 'contable'],
    keyWordsSubtitulo: ['automatizar'],
    items: ['Traspaso automático de los datos', 'Construcción automática del formulario 29 gracias a la lectura directa del registro de compras, ventas y activo fijo y remuneraciones.', 'Conciliación automátizada del registro de compras y ventas del sii', 'Generación y envio automático de un sin numero de declaraciones.', 'Genera certificados asociados a las declaraciones.'],
    // video: video3,
  },
  // {
  //   bg_m: bg4_m,
  //   bg_w: bg4_w,
  //   titulo: 'Plan PERIODO de OPERACIÓN RENTA 2023',
  //   subtitulo1: 'Desde 12 uf con un usuario y empresas ilimitadas',
  //   subtitulo2: '',
  //   subtitulo3: '',
  //   keyWordsTitulo: [],
  //   keyWordsSubtitulo: ['automatizar'],
  //   items: ['Webinar y clases programadas', 'Soporte calificado que siempre responde', 'Proceso de implementación asistido con ayuda directa en la parametrización y configuración.', 'consulta también por el plan anual'],
  //   // video: video4,
  // },
]

export default slider
