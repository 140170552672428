import { Grid } from '@chakra-ui/react';
import React from "react";
import { animateScroll as scroll } from "react-scroll";

import Acomodamos from "../../components/Home/Acomodamos";
import Compromiso from "../../components/Home/Compromiso";
import Confian from "../../components/Home/Confian";
import Diferencia from "../../components/Home/Diferencia";
import Herramientas from "../../components/Home/Herramientas";
import Productividad from "../../components/Home/Productividad";
import Slider from "../../components/Home/Slider";
import PreguntasFrecuentes from "../../components/Shared/PreguntasFrecuentes";

export default function Home() {
  React.useEffect(() => {
    scroll.scrollToTop();
  });
  React.useEffect(() => {
    window.localStorage.removeItem("plan");
  }, [])

  return (
    <Grid >
      <Slider />
      {/* <Webinar /> */}
      <Herramientas />
      <Diferencia />
      <Productividad />
      <Acomodamos />
      {/* <Planes isComponent={true} /> */}
      {/* <ProductosAContratar isComponent={true} /> */}
      <Confian />
      <Compromiso />
      <PreguntasFrecuentes />
    </Grid >
  );
}
