import React from "react";
import {
  Grid,
} from '@chakra-ui/react';

import Simulacion from "../../components/Simular/Simular"
import PreguntasFrecuentes from "../../components/Shared/PreguntasFrecuentes"
import simular_bg from "../../administracion/jsons_images/Simular/simular_bg.svg"

export default function Simular() {

  return (
    <Grid
      bg={`url(${simular_bg}) no-repeat`}
      bgSize="100% auto"
    >
      <Simulacion />
      <PreguntasFrecuentes />
    </Grid >
  );
}
