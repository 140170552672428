import React from "react";
import {
  Grid,
  Button,
} from '@chakra-ui/react';

export default function SiNo({ ...props }) {
  const { answers, setAnswers, id, currentQuestion, setCurrentQuestion } = props

  React.useEffect(() => {
    if (currentQuestion.answer === "Sí" && currentQuestion.subAnswer) {
      {
        Object.keys(currentQuestion.subAnswer).map(key => (
          setAnswers({
            ...answers,
            [key]: {
              pregunta: currentQuestion.subAnswer[key].question,
              respuesta: "0"
            }
          })
        ))
      }
    } else {
      if (currentQuestion.subAnswer) {
        Object.keys(currentQuestion.subAnswer).map(key => (
          delete answers[key]
        ))
      }
    }
  }, [currentQuestion.answer]);

  const handleChange = (option) => {
    setCurrentQuestion({
      ...currentQuestion,
      answer: option,
      nextQuestion: currentQuestion.answers[option].nextQuestion
    })
    setAnswers({
      ...answers,
      [id]: {
        pregunta: currentQuestion.question,
        respuesta: option
      }
    })
  }

  return (
    <>
      <Grid
        display="flex"
        gap={5}
        m="auto"
        pt="3%"
      >
        <Button
          bg="transparent"
          border="2px"
          borderRadius="xl"
          borderColor={currentQuestion.answer === "Sí" ? "#BEE625" : "#EEEFED"}
          onClick={() => handleChange("Sí")}
          color="#BEE625"
          h="100%"
          py="5%"
          px="25%"
        >
          Sí
        </Button>
        <Button
          bg="white"
          border="2px"
          borderRadius="xl"
          borderColor={currentQuestion.answer === "No" ? "#BEE625" : "#EEEFED"}
          onClick={() => handleChange("No")}
          color="#BEE625"
          h="100%"
          py="5%"
          px="25%"
        >
          No
        </Button>
      </Grid>
    </>
  );
}
