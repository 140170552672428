import React from "react";
import {
  Box,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Icon,
} from '@chakra-ui/react';

export default function Progress({ ...props }) {
  const { progressQ } = props
  function CircleIcon(props) {
    return (
      <Icon viewBox="0 0 200 200" {...props}>
        <path
          fill="#A5C918"
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
      </Icon>
    )
  }

  return (
    <>
      <Box display="flex">
        <Slider
          aria-label="slider-ex-2"
          max={progressQ.total}
          min={1}
          step={1}
          defaultValue={1}
          value={progressQ.actual}
          size={"lg"}
          isDisabled
          width="40%"
          ml="30%"
        >
          <SliderTrack>
            <SliderFilledTrack bg="linear-gradient(280deg, rgba(165,201,24,1) 32%, rgba(247,255,213,1) 100%)" />
          </SliderTrack>
          <SliderThumb >
            <Box as={CircleIcon} />
          </SliderThumb>
        </Slider>
        <Text fontSize="12px" fontStyle="italic" color="#B7B7B7" ml="2%">{`${progressQ.actual} de ${progressQ.total}`}</Text>
      </Box>
    </>
  );
}
