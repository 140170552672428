import nos01 from './nos01.png'
import nos02 from './nos02.png'
import conocenos_bg from './conocenos_bg.svg'

const conocenos = {
  bg: conocenos_bg,
  titulo1: 'Conócenos',
  titulo2: 'un',
  titulo3: 'poco',
  imagen1: nos01,
  contenido1:
    'Nuestra empresa es fundada el año 2012 y nace de la necesidad de Auditax, empresa del grupo dedicada a la asesoría contable y tributaria; de contar con una herramienta eficiente que permitiera a su equipo facilitar su gestión diaria y la de sus clientes.',
  contenido2:
    'Es así como Francisco Inostroza B. y Lautaro Moreira A. contadores Auditores con más de mas de 30 años de experiencia en el rubro contable tributario fundaron Audisoft como una herramienta de uso interno disponible solo para usuarios de Auditax.',
  imagen2: nos02,
  contenido3:
    'Poco tiempo después <strong > el software fue descubierto por profesionales del área que solicitaron su comercialización</strong>, iniciando así un proceso de mejora contuinua que se mantiene hasta el día de hoy.',
  contenido4:
    'Actualmente, somos parte de la labor diaria de clientes en todo Chile, que nos han elegido por ser la única marca que además de satisfacer las necesidades actuales en gestión contable, financiera e impositiva, cuenta con un fuerte enfoque en el cumplimiento tributario.',
  contenido5:
    '<strong > Nuestro diseño de servicio “Siempre Atento”</strong> está respaldado por nuestra orientación hacia la satisfacción de nuestros clientes y por estar constantemente introduciendo innovadoras funcionalidades y soluciones a nuestros productos y soporte, con el fin de facilitar la gestión de contadores, gerentes financieros, empresarios y profesionales que requieren cada día realizar sus actividades en menor tiempo, con mayor precisión y seguridad.',
  contenido6:
    'Un factor clave para nosotros son los valores y preparación de nuestro equipo de trabajo, altamente calificado y cercanos a quienes trabajan en la gestión contable, financiera y tributaria cada día. Creado por contadores para contadores y profesionales de hoy.',
}

export default conocenos
