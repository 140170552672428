import React from "react";
import {
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import ERP from "../../../administracion/jsons_images/Productos/ERP/ERP"

export default function Header() {
  return (
    <Grid
      bgImage={{
        md: ERP.bg_w,
        base: ERP.bg_m
      }}
      bgSize="cover"
      // maxHeight='700px'
      // minHeight='700px'
      backgroundRepeat='no-repeat'
      pl={{ md: "50%", base: "7%" }}
      pt={{ base: '500px', md: '200px' }}
      pb={{ base: '50px', md: '200px' }}
    >
      <GridItem
        display="inline"
        float="center"
      >
        <Text fontSize="30px" fontWeight={"bold"} pl="30px" as={"mark"} bgColor="#B2D237">
          {ERP.titulo1}{" "}
        </Text>
        <Text as="span" fontSize="30px" fontWeight={'bold'}>
          {ERP.titulo2}
        </Text>
        <Text fontSize="30px" fontWeight={'bold'} pl="30px">
          {ERP.titulo3}
        </Text>
        <Text fontSize="30px" fontWeight={"bold"} pl="30px">
          {ERP.titulo4}
        </Text>
      </GridItem>
      <GridItem
        w={{ lg: "50%", base: "80%" }}
        pl="30px"
      >
        <Text fontSize="12px">
          {parse(ERP.subtitulo)}
        </Text>
      </GridItem>
    </Grid >
  );
}
