import React from "react";
import {
  Grid,
} from '@chakra-ui/react';

import Header from "../../components/Productos/PDV/Header"
import Modulos from "../../components/Productos/PDV/Modulos"
// import Contactanos from "../../components/Shared/Contactanos"

export default function PDV() {

  return (
    <Grid>
      <Header />
      <Modulos />
      {/* <Contactanos /> */}
    </Grid >
  );
}
