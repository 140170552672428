import React from 'react'
import { Grid } from '@chakra-ui/react'

import Header from '../../components/Productos/ERP/Header'
import Modulos from '../../components/Productos/ERP/Modulos'
import ModulosEspeciales from '../../components/Productos/ERP/ModulosEspeciales'
// import Contactanos from '../../components/Shared/Contactanos'

export default function ERP() {
  return (
    <Grid>
      <Header />
      <Modulos />
      <ModulosEspeciales />
      {/* <Contactanos /> */}
    </Grid>
  )
}
