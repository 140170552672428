import React from "react";
import {
  Grid,
} from '@chakra-ui/react';

import Planes from "../../components/Shared/Planes"
import Necesidades from "../../components/Planes/Necesidades"
import PreguntasFrecuentes from "../../components/Shared/PreguntasFrecuentes"

export default function Productos() {

  return (
    <Grid >
      <Planes isComponent={false} />
      <Necesidades />
      <PreguntasFrecuentes />
    </Grid >
  );
}
