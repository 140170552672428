const punto = {
  basic: {
    titulo: 'Punto de venta Basic',
    uf: '2 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '1,6',
    perYear: 'Al mes x año',
    contenido: [
      '1 Terminal de venta Gestion de ventas',
      'Control de inventario ',
      'Roles y permisos ',
      'Informes estratégicos',
      'Emisión de DTE',
    ],
  },
  full: {
    titulo: 'Punto de venta + contador',
    uf: '5 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '4 UF',
    perYear: 'Al mes x año',
    contenido: [
      '1 Terminal de venta Gestion de ventas',
      'Control de inventario ',
      'Roles y permisos ',
      'Informes estratégicos',
      'Emisión de DTE',
      'Contabilidad',
      'Remuneraciones',
      'Tributario básico',
      'Financiero',
      'Software Contador con 3 Usuarios y Empresas Ilimitadas',
    ],
  },
  expert: {
    titulo: 'Punto de venta + ERP',
    uf: '7 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '5,6 UF',
    perYear: 'Al mes x año',
    contenido: [
      '1 Terminal de venta Gestion de ventas',
      'Control de inventario ',
      'Roles y permisos ',
      'Informes estratégicos',
      'Emisión de DTE',
      'Sofware ERP para 3 empresas y 3 usuarios ',
      'Compras',
      'Ventas',
      'Tesorería',
      ' IVA ',
      'Activo fijo',
      'Recursos Humanos',
    ],
  },
}

export default punto