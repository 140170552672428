import React from "react";
import {
  Grid,
  GridItem,
  Text,
  Image,
  // Box,
  // Center,
  SimpleGrid,
} from '@chakra-ui/react';

import equipo from "../../administracion/jsons_images/Nosotros/Equipo/equipo"
import equipo_bg from "../../administracion/jsons_images/Nosotros/Equipo//equipo_bg.svg"
import "./equipo.css"

export default function Equipo() {
  // const [isVisible, setIsVisible] = React.useState({});

  // const over = (position) => {
  //   setIsVisible({
  //     ...isVisible,
  //     [position]: true
  //   })
  // }

  // const out = () => {
  //   setIsVisible({})
  // }

  return (
    <Grid>
      <Grid m="auto" w="80%">
        <Grid
          display="inline"
          float="center"
          m="auto"
          py={{
            base: '15px',
            md: '15px',
            lg: '15px',
            xl: '10px',
          }}
        >
          <Text as="span" fontSize="30px" fontWeight={'bold'}>
            Nuestro{' '}
          </Text>
          <Text
            as="span"
            fontSize="30px"
            fontWeight={'bold'}
            pr="30px"
            bgColor="#BEE625"
          >
            equipo
          </Text>
        </Grid>


        <SimpleGrid
          columns={{ base: 6, md: 10, lg: 10, xl: 7 }} // Adjust the number of columns based on your design

          // center

          m="auto"
          pt={{ base: '15px', md: '15px' }}
          spacing={{ base: 2, md: 9 }}
          // w={{ base: '100%', lg: '70%', xl: '75%' }}
          w={{ base: '100%', lg: '80%', xl: '75%' }} // Adjust the width based on your design

        >
          {equipo.map((eq, index) =>
            // saltar primer elemento
            index > 1 ? (
              <GridItem colSpan={{ base: 2, md: 2, lg: 2, xl: 1 }} key={index}>
                <Image src={eq.foto} borderRadius={'50%'} />
                <Text
                  textAlign={'center'}
                  fontSize={{ base: '16px', md: '20px' }}
                  my="8px"
                >
                  {eq.nombre}
                </Text>
              </GridItem>
            ) : null
          )}
        </SimpleGrid>
      </Grid>
    </Grid>
  );
}
