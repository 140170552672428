const empresas = {
  basic: {
    titulo: 'Erp Basic',
    uf: '5 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '4 UF',
    perYear: 'Al mes x año',
    contenido: [
      '3 Empresas',
      '3 Usuarios',
      'Compras',
      'Ventas',
      'Tesorería',
      'IVA',
      'Activo fijo',
      'Recursos Humanos',
    ],
  },
  full: {
    titulo: 'Erp Full',
    uf: '7,7 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '6,2 UF',
    perYear: 'Al mes x año',
    contenido: [
      '3 Empresas',
      '3 Usuarios',
      'Compras',
      'Ventas',
      'Tesorería',
      'IVA',
      'Activo fijo',
      'Recursos Humanos',
      '1 Empresa con emisión de DTE',
      'Software Audisoft Tributario con 1 usuario y empresas ilimitadas',
    ],
  },
  expert: {
    titulo: 'Erp Expert',
    uf: '10 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '8 UF',
    perYear: 'Al mes x año',
    contenido: [
      '3 Empresas',
      '3 Usuarios',
      'Compras',
      'Ventas',
      'Tesorería',
      'IVA',
      'Activo fijo',
      'Recursos Humanos',
      ' 1 Empresa con emisión de DTE ',
      '1 Empresa con LCE',
      '1 Empresa con LRE',
      'Software Audisoft Tributario Avanzado con 1 usuario y empresas ilimitadas',
    ],
  },
}

export default empresas
