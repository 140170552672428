import React from 'react';

export default function StyleText({ text, keyWords, background }) {
  let style = '';
  // if (background === true) {
  style = 'background-color:#A5C918;';
  // } else {
  //   style = 'color: #A5C918';
  // }

  // replace only the keyWords in the text with the new style
  const words = text.replace(
    new RegExp(keyWords.join('|'), 'gi'),
    match => `<span style='${style}'>${match}</span>`
  );

  return <span dangerouslySetInnerHTML={{ __html: words }} />;
}
