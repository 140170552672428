import { Grid } from '@chakra-ui/react'
import React from 'react'

import Header from '../../components/Productos/Contador/Header'
import Modulos from '../../components/Productos/Contador/Modulos'

// import Contactanos from '../../components/Shared/Contactanos'

function App() {
  return (
    <Grid>
      <Header />
      <Modulos />
      {/* <Contactanos /> */}
    </Grid>
  )
}

export default App
