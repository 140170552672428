import bg_m from './pdv_m_bg.png'
import bg_w from './pdv_w_bg.png'

import mod01 from './Modulos/mod01.svg'
import mod02 from './Modulos/mod02.svg'
import mod03 from './Modulos/mod03.svg'
import mod04 from './Modulos/mod04.svg'

const PDV = {
  bg_w: bg_w,
  bg_m: bg_m,
  titulo1: '¡Logra el control',
  titulo2: 'total de tu e',
  titulo3: 'mpresa',
  subtitulo:
    'No pierdas mas tiempo, maximiza tu productividad integrando <strong> el punto de venta a tu negocio, integra sucursales, bodegas e inventarios, para emitir tus boletas y facturas.</strong> Con nuestro <strong> sistema 100% web </strong> que se integra con Audisoft Contador o Audisoft ERP, podrás llevar tu contabilidad al día con el detalle de tus ventas e inventarios en línea.',
  modulos: {
    titulo1: 'Algunos de sus',
    titulo2: 'módulos',
    contenido: [
      {
        titulo: 'Gestión de Ventas',
        texto:
          'Organiza y controla tu proceso de venta, gestiona distintos medios de pagos como Transbank, getNet o control de créditos, realiza descuentos, genera ticket de pre-venta compatible con cualquier pistola de barra o QR. Controla tus ventas, gestiona comisiones entre otros.',
        imagen: mod01,
      },
      {
        titulo: 'Inventario',
        texto:
          'Maneja todo tu inventario en un solo lugar, ingresa tus productos y administra sus códigos, define sucursales y bodegas, automatiza el control de tu stock. Define stock critico por producto según estadísticas de venta y mantén tu inventario la cantidad correcta.',
        imagen: mod02,
      },
      {
        titulo: 'Roles y Permisos',
        texto:
          'Podrás asignar distintos roles a tus trabajadores, con distintas responsabilidades y con distintos niveles de permisos para visualizar o editar información importante para la gestión de tu negocio.',
        imagen: mod03,
      },
      {
        titulo: 'Informes estratégicos instantáneos',
        texto:
          'En cualquier momento puedes visualizar el stock valorizado de tus bodegas o sucursales, las ventas por vendedor, productos con stock critico, top de ventas por producto, categoría de producto o familia de producto, productos de mayor y menor rotación etc.',
        imagen: mod04,
      },
    ],
  },
}

export default PDV
