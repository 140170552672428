import React from "react";
import {
  Grid,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

export default function Cantidad({ ...props }) {
  const { title, subtitle, id, answers, setAnswers, defVal, min, max } = props
  const [finalValue, setFinalValue] = React.useState(0);

  const handleChange = (event) => {
    setFinalValue(event)
    setAnswers({
      ...answers,
      [id]: {
        pregunta: title,
        respuesta: event
      }
    })
  }

  return (
    <>
      <Grid m="auto" pt="5%" w="90%" textAlign="center">
        <Text fontSize="16px" fontWeight="bold" m="auto">
          {title}
        </Text>
        {subtitle && <Text fontSize="12px" m="auto">
          {subtitle}
        </Text>}
        <Grid m="auto" pt="5%">
          <NumberInput
            size="md"
            maxW={24}
            max={max}
            min={min}
            value={finalValue}
            defaultValue={defVal}
            onChange={handleChange}
          >
            <NumberInputField borderColor="#BEE625" color="#A5C918" />
            <NumberInputStepper>
              <NumberIncrementStepper color="white" bg="#A5C918" />
              <NumberDecrementStepper color="white" bg="#A5C918" />
            </NumberInputStepper>
          </NumberInput>
        </Grid>
      </Grid>
    </>
  );
}
