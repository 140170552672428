import React from "react";
import {
  Grid,
  GridItem,
  Image,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import PDV from "../../../administracion/jsons_images/Productos/PDV/PDV"

export default function Modulos() {
  return (
    <Grid
      px={{ lg: "20%", base: "8%" }}
      pt="25px"
      pb="10%"
    >
      <Grid
        display="inline"
        float="center"
        m="auto"
        textAlign="center"
        pb="10%"
      >
        <Text as="span" fontSize="30px" fontWeight={800}>{PDV.modulos.titulo1}{" "}</Text>
        <Text fontSize="30px" fontWeight={800} pl="5px" pr="30px" as={"mark"} bgColor="#BEE625">{PDV.modulos.titulo2}</Text>
      </Grid>
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(6, 1fr)", sm: "repeat(6, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(4, 1fr)" }}
        gap={{ lg: 1, base: 3 }}
      >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={PDV.modulos.contenido[0].imagen} boxSize="200px" m="auto" alt="mod01" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {PDV.modulos.contenido[0].titulo}
          </Text>
          <Text fontSize="12px" w="80%">
            {parse(PDV.modulos.contenido[0].texto)}
          </Text>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          display={{ md: 'none' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={PDV.modulos.contenido[1].imagen} boxSize="200px" m="auto" alt="mod02" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {PDV.modulos.contenido[1].titulo}
          </Text>
          <Text fontSize="12px" w={{ base: "100%", lg: "80%" }}>
            {parse(PDV.modulos.contenido[1].texto)}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, md: 1, lg: 1 }}
          display={{ base: 'none', md: 'block' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={PDV.modulos.contenido[1].imagen} boxSize="200px" m="auto" alt="mod02" />
          </ScaleFade>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={PDV.modulos.contenido[2].imagen} boxSize="200px" m="auto" alt="mod03" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {PDV.modulos.contenido[2].titulo}
          </Text>
          <Text fontSize="12px">
            {parse(PDV.modulos.contenido[2].texto)}
          </Text>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          display={{ md: 'none' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={PDV.modulos.contenido[3].imagen} boxSize="200px" m="auto" alt="mod04" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {PDV.modulos.contenido[3].titulo}
          </Text>
          <Text fontSize="12px">
            {parse(PDV.modulos.contenido[3].texto)}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          display={{ base: 'none', md: 'block' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={PDV.modulos.contenido[3].imagen} boxSize="200px" m="auto" alt="mod04" />
          </ScaleFade>
        </GridItem>

      </Grid>
    </Grid >
  );
}
