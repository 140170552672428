import React from "react";
import {
  GridItem,
  Grid,
  Image,
  Text,
  ScaleFade,
} from '@chakra-ui/react';

import confian from "../../administracion/jsons_images/Home/Confian/confian"

export default function Confian() {

  return (
    <Grid
      px={{ lg: "10%", base: "5%" }}
      pt="8%"
    >
      <Grid
        display="inline"
        float="center"
        m="auto"
        pb="5%"
      >
        <Text
          fontSize="30px"
          fontWeight={"bold"}
          pl={{ lg: "30px", base: "10px" }}
          // pr="10px"
          as={"mark"}
          bgColor="#BEE625"
        >
          Confian
        </Text>
        <Text as="span" fontSize="30px">{" "}en nosotros</Text>
      </Grid>
      <Grid
        templateColumns={{ base: "repeat(3, 1fr)", sm: "repeat(3, 1fr)", md: "repeat(6, 1fr)", lg: "repeat(6, 1fr)" }}
        templateRows={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)" }}
        gap={5}
      >
        {confian.map((conf, index) => (
          <GridItem
            key={index}
            colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
            rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          >
            <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
              <Image src={conf.logo} alt={conf.alt} m="auto" />
            </ScaleFade>
          </GridItem>
        ))}
      </Grid>
    </Grid >
  );
}
