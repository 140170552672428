import {
    Box,
    Grid,
    List,
    ListIcon,
    ListItem,
    Show,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import { modulosDeclaraciones } from '../../../administracion/jsons_images/Productos/DeclaracionesJuradas/DeclaracionesJuradas';
import { CheckCircleIcon } from '@chakra-ui/icons';

function DeclaracionesJuradas() {
    const Modulo = ({ title, list }) => {
        return (
            <Box
            // maxW="sm"
            // borderRadius="lg"
            // overflow="hidden"
            // p="5%"
            // m="auto"
            // textAlign="center"
            // h="100%"
            >
                <Text
                    textAlign={'left'}
                    fontSize="2xl"
                    lineHeight={1}
                    fontWeight="extrabold"
                    color={COLORS.primary}
                    mb={'5%'}
                    minH={'50'}
                >
                    {title}
                </Text>
                {/* cretae a list with chakra ui */}
                <List h={'100%'} textAlign={'left'}>
                    {list.map((item, index) => (
                        <ListItem key={index} pb={1}>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            {item}
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    };

    const Modulos = () => (
        <Grid px={{ xl: '18%', lg: '4%', base: '5%' }} pt="1%">
            <Grid display="inline" m="auto" pb="5%">
                <SimpleGrid columns={[1, 2, 3, 4]}>
                    {modulosDeclaraciones.modulos.map((modulo, index) => (
                        <Modulo key={index} title={modulo.title} list={modulo.list} />
                    ))}
                </SimpleGrid>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid
                id="DeclaracionesJuradas"
                px={{ xl: '20%', lg: '6%', md: '7%', base: '5%' }}
                pt="4%"
            >
                <Grid display="inline">
                    <SimpleGrid>
                        <Grid
                            templateColumns={{ lg: 'repeat(2, 1fr)', base: 'repeat(1, 1fr)' }}
                            gap={6}
                            w="100%"
                        >
                            <Grid alignSelf={'start'}>
                                <Text
                                    fontSize="3xl"
                                    fontWeight="semibold"
                                    color={COLORS.primary}
                                >
                                    {modulosDeclaraciones.titulo1}
                                </Text>
                                <Text
                                    fontSize="5xl"
                                    fontWeight="bold"
                                    lineHeight={1}
                                    pb="5%"
                                    color={COLORS.secondary}
                                >
                                    {modulosDeclaraciones.titulo2}
                                </Text>
                                <Text
                                    fontSize="2xl"
                                    fontWeight="extrabold"
                                    color={COLORS.primary}
                                    pb={'2%'}
                                >
                                    {modulosDeclaraciones.subtitulo1}
                                </Text>

                                <List>
                                    {modulosDeclaraciones.caracteristicas.map((item, index) => (
                                        <ListItem pb={1} key={index}>
                                            <ListIcon as={CheckCircleIcon} color="green.500" />
                                            {item}
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Show above="lg">
                                <Box alignItems={'end'} justifySelf={'center'}>
                                    <img
                                        display={{ xl: 'none', lg: 'none', base: 'block' }}
                                        style={{ borderRadius: '50%' }}
                                        src={modulosDeclaraciones.imagen}
                                        alt="declaraciones-juradas"
                                        border="0"
                                    />
                                </Box>
                            </Show>
                        </Grid>
                    </SimpleGrid>
                </Grid>
                {/* Create a row with 4 card with title, subtitle and a list*/}
            </Grid>
            <Modulos />
        </>
    );
}

export default DeclaracionesJuradas;
