import React from "react";
import {
  Grid,
  Text,
} from '@chakra-ui/react';

import Cantidad from "../Shared/Simular/Cantidad"
import SiNo from "../Shared/Simular/SiNo"
import Opciones from "../Shared/Simular/Opciones"

export default function DTE({ ...props }) {
  const { answers, setAnswers, currentQuestion, setCurrentQuestion } = props

  return (
    <>
      <Grid m="auto" pt="10%" >
        <Text fontSize="16px" fontWeight="bold">
          {currentQuestion.question}
        </Text>
      </Grid>

      {/* RECICLABLE */}
      <SiNo
        answers={answers}
        setAnswers={setAnswers}
        id={currentQuestion.id}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
      />

      {currentQuestion.answer === "Sí" &&
        <>
          {Object.keys(currentQuestion.subAnswer).map(key => (
            currentQuestion.subAnswer[key].type === "select" ?
              <Opciones
                key={key}
                options={currentQuestion.subAnswer[key].answers}
                title={currentQuestion.subAnswer[key].question}
                subtitle={currentQuestion.subAnswer[key].subtitle}
                id={currentQuestion.subAnswer[key].id}
                answers={answers}
                setAnswers={setAnswers}
              />
              : currentQuestion.subAnswer[key].type === "number" &&
              <Cantidad
                key={key}
                title={currentQuestion.subAnswer[key].question}
                subtitle={currentQuestion.subAnswer[key].subtitle}
                id={currentQuestion.subAnswer[key].id}
                answers={answers}
                setAnswers={setAnswers}
                defVal={0}
                min={0}
                max={24}
              />
          ))}
        </>
      }
    </>
  );
}
