import React from 'react'
import { useNavigate } from "react-router-dom";
import { Grid, GridItem, Button, Text, Box } from '@chakra-ui/react'
import parse from 'html-react-parser';

import necesidades from "../../administracion/jsons_images/Planes/necesidades.json"

export default function Necesidades() {
  const history = useNavigate();

  const handleChangePage = (link) => {
    history(link);
  }

  return (
    <Grid m={"auto"} py={{ base: "100px" }} gap={3}>
      <Grid textAlign="center" w={{ base: "60%" }} m="auto">
        <Text fontSize="30px" fontWeight={"bold"}>
          {parse(necesidades.titulo)}
        </Text>
      </Grid>
      <Grid textAlign="center" w={{ base: "45%" }} m="auto">
        <Text fontSize="16px">
          {parse(necesidades.subtitulo)}
        </Text>
      </Grid>
      <Grid display="flex" gap={5} m="auto">
        <GridItem>
          <Button
            border={'1px'}
            borderRadius="15px"
            borderColor="#A5C918"
            variant="outline"
            py="40px"
            px={{ base: "15px", md: "60px" }}
            boxShadow="0px 1px 8px 1px #d1f056"
            onClick={() => handleChangePage('/Simular-cotizacion')}
          >
            <Box>
              <Text color="#B2D237">Simula</Text>
              <Text color="#B2D237">tu cotización</Text>
            </Box>
          </Button>
        </GridItem>
        <GridItem>
          <Button
            border={'1px'}
            borderRadius="15px"
            borderColor="#A5C918"
            variant="outline"
            py="40px"
            px={{ base: "15px", md: "60px" }}
            boxShadow="0px 1px 8px 1px #d1f056"
            onClick={() => handleChangePage('/Contacto')}
          >
            <Text color="#B2D237">Contáctanos</Text>
          </Button>
        </GridItem>
      </Grid>
    </Grid>
  )
}
