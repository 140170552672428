import doc from './doc.svg'
import edificio from './edificio.svg'
import support from './support.svg'
import usuario from './usuario.svg'

const productividad = {
  doc: doc,
  edificio: edificio,
  support: support,
  usuario: usuario,
  titulo1: 'Cada vez mas empresas',
  titulo2: 'aumentan su productividad',
  titulo3: 'con Audisoft',
  indicadores: {
    empresas: 5.276,
    usuarios: 1.628,
    DTE: 15.8,
    calificacion: 90,
  },
}

export default productividad
