import React, { useEffect, useState } from "react";
import {
  Grid,
  Text,
  Button,
  CircularProgress,
} from '@chakra-ui/react';

const configHubspot = {
  portalId: process.env.REACT_APP_HUBSPOT_PORTALID,
  region: process.env.REACT_APP_HUBSPOT_REGION,
  formId: process.env.REACT_APP_HUBSPOT_FORMID
}

export default function Botones({ ...props }) {
  const {
    currentQuestion,
    handleNext,
    handlePrev,
    formData,
    setFormData,
    error,
    setError,
    initStateFormData,
    resultData,
    questionStates,
    setQuestionStates,
  } = props
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentQuestion.answer && currentQuestion.required) {
      setDisabled(true)
    } else if (currentQuestion.required) {
      setDisabled(false)
    } else {
      setDisabled(false)
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (
      !formData.companyName ||
      !formData.contactName ||
      !formData.contactLastName ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [formData]);

  useEffect(() => {
    setFormData({
      ...formData,
      message: JSON.stringify(resultData)
    })
  }, [resultData]);

  useEffect(() => {
    setTimeout(() => {
      setError(false)
    }, 8000);
  }, [error]);

  const handleContact = (event) => {
    event.preventDefault()
    setQuestionStates({
      isFinished: false,
      isContactForm: true,
      isResult: false
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    const submit = async () => {
      try {
        var xhr = new XMLHttpRequest();
        var url = `https://api.hsforms.com/submissions/v3/integration/submit/${configHubspot.portalId}/${configHubspot.formId}`
        var data = {
          "fields": [
            {
              "name": "firstname",
              "value": formData.contactName
            },
            {
              "name": "lastname",
              "value": formData.contactLastName
            },
            {
              "name": "email",
              "value": formData.email
            },
            {
              "name": "mobilephone",
              "value": formData.phone
            },
            {
              "name": "company",
              "value": formData.companyName
            },
            {
              "name": "message",
              "value": formData.message
            },
          ],
          "context": {
            "pageUri": "www.audisoft.com",
            "pageName": "Audisoft web page"
          },
        }
        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            setFormData(initStateFormData)
            setQuestionStates({
              ...questionStates,
              isContactForm: false,
              isFinished: true,
            })
            setLoading(false)
          } else if (xhr.readyState === 4 && xhr.status === 403) {
            setError(true)
            alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.     
            setLoading(false)
          } else if (xhr.readyState === 4 && xhr.status === 404) {
            setError(true)
            alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found    
            setLoading(false)
          }
        }
        // Sends the request 
        await xhr.send(final_data)
      } catch (error) {
        setLoading(false)
        setError(true)
      }
    }
    submit()
  }

  return (
    <>
      {!questionStates.isFinished &&
        <Grid
          display="flex"
          gap={3}
          m="auto"
          px="15%"
          pt="4%"
        >
          {currentQuestion.id !== "Q_TIPO" &&
            <Button
              bg="white"
              border="1px"
              borderRadius="20px"
              borderColor="#BEE625"
              onClick={handlePrev}
            >
              <Text fontSize="12px" color="#B2D237">
                Regresar
              </Text>
            </Button>
          }
          {currentQuestion.id !== "LAST" && !questionStates.isContactForm &&
            <Button
              border="1px"
              borderRadius="20px"
              borderColor="#BEE625"
              bg="white"
              disabled={disabled}
              onClick={handleNext}
            >
              <Text fontSize="12px" color="#B2D237">
                Siguiente
              </Text>
            </Button>
          }
          {currentQuestion.id === "LAST" && !questionStates.isContactForm &&
            <Button
              border="1px"
              borderRadius="20px"
              borderColor="#B2D237"
              bg="#B2D237"
              onClick={handleContact}
            >
              <Text fontSize="12px" color="white">
                ¡Lo quiero!
              </Text>
            </Button>
          }
          {questionStates.isContactForm &&
            <>
              {!loading ?
                <Button
                  border="1px"
                  borderRadius="20px"
                  borderColor="#B2D237"
                  bg="#B2D237"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  <Text color="white"> Enviar</Text>
                </Button>
                :
                <CircularProgress isIndeterminate color="#B2D237" />
              }
            </>
          }
        </Grid>
      }
    </>
  );
}
