import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  ScaleFade,
} from '@chakra-ui/react';

import diferencia from '../../administracion/jsons_images/Home/Diferencia/diferencia'

export default function Diferencia() {

  return (
    <Grid
      px={{ base: "5%", md: "13%" }}
      pt={{ base: "25%", md: "8%" }}
    >
      <Grid
        display="inline"
        float="center"
        textAlign="center"
      >
        <Text fontSize="30px" fontWeight={"bold"} pl={{ base: "40px", md: "30px" }} as={"mark"} bgColor="#BEE625">
          {diferencia.titulo1}
        </Text>
        <Text as="span" fontSize="30px" fontWeight={"bold"}>
          {" "}{diferencia.titulo2}
        </Text>
      </Grid >
      <Grid m="auto" w="80%" textAlign="center">
        <Text fontSize="14px">
          {diferencia.subtitulo}
        </Text>
      </Grid>

      {/* Trayectoria */}
      <Grid
        templateColumns={{ base: "repeat(1)", sm: "repeat(1)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(2)", sm: "repeat(2)", md: "repeat(1)", lg: "repeat(1)" }}
        mt={{ md: "4%", base: "9%" }}
        mx={{ md: "10%", base: "5px" }}
      >
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          w={"80%"}
          m={{ base: "auto" }}
        >
          <Text fontSize="30px" fontWeight={600}>
            Trayectoria
          </Text>
          <Text fontSize={{ base: "12px", lg: "14px" }} textAlign={"left"}>
            {diferencia.Trayectoria.descripcion}
          </Text>
        </GridItem >
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image
              src={diferencia?.Trayectoria?.imagen}
              w={{ base: "50%" }}
              m={{ base: "auto" }}
              alt="escalar"
            />
          </ScaleFade>
        </GridItem>
      </Grid >

      {/* Experiencia */}
      <Grid
        mx={{ base: "2%", md: "10%" }}
        pt={"4%"}
      >
        <Box
          background={{ md: "linear-gradient(90deg, rgba(242,242,255,1) 0%, rgba(230,253,179,1) 35%, rgba(248,253,208,1) 100%)", base: "linear-gradient(90deg, rgba(248,253,208,1) 20%, rgba(230,253,179,1) 60%, rgba(242,242,255,1) 92%)" }}
          borderRadius={{ md: "18px 103px 17px 103px", base: "103px 15px 103px 15px" }}
        >
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
            templateRows={{ base: "repeat(2)", sm: "repeat(2)", md: "repeat(1, 1fr)", lg: "repeat(1, 1fr)" }}
          >
            <GridItem
              colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
              rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
              mt={"4%"}
              ml={"9%"}
              mb={"4%"}
              display={{ base: "none", md: "flex" }}
            >
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image
                  src={diferencia.Experiencia.imagen}
                  w={{ base: "79%" }}
                  h={{ base: "79%" }}
                  right={0}
                  alt="pensar"
                />
              </ScaleFade>

            </GridItem >
            <GridItem
              colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              position={{ base: "absolute", md: "static" }}
            >
              <Grid
                mt={{ base: "5%", md: "30px", lg: "95px" }}
                ml={{ base: "30%", md: "10%", lg: "30%" }}
                mr={{ base: "16%", md: "14px", lg: "30%" }}
              >
                <Text fontSize="30px" fontWeight={600} textAlign="right">
                  Experiencia
                </Text>
              </Grid >
              <Grid
                ml={{ base: "50px", md: "4px", }}
                w={{ base: "70%", md: "100%", lg: "80%" }}
                p={{ base: 5, md: 10 }}
              >
                <Text fontSize="12px" textAlign="right">
                  {diferencia.Experiencia.descripcion}
                </Text>
              </Grid >
            </GridItem >
            <GridItem
              colSpan={1}
              rowSpan={1}
              mt={"40%"}
              ml={"3%"}
              mb={"0%"}
              display={{ base: "flex", md: "none" }}
            >
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image
                  src={diferencia.Experiencia.imagen}
                  w={"60%"}
                  h={"79%"}
                  right={0}
                  alt="pensar"
                />
              </ScaleFade>
            </GridItem >
          </Grid>
        </Box>
      </Grid>

      {/* Soporte */}
      <Grid
        templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(2)", sm: "repeat(2)", md: "repeat(1)", lg: "repeat(1)" }}
        mt={{ md: "4%", base: "9%" }}
        mx={{ md: "10%", base: "5px" }}
      >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          w={"80%"}
          m={{ base: "auto" }}
        >
          <Text fontSize="30px" fontWeight={600} >
            Soporte
          </Text>
          <Text fontSize="12px" textAlign="left">
            {diferencia.Soporte.descripcion}
          </Text>
        </GridItem >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image
              src={diferencia.Soporte.imagen}
              w={{ md: "75%", base: "79%" }}
              h={{ base: "79%" }}
              m={{ base: "auto" }}
              alt="soporte"
            />
          </ScaleFade>

        </GridItem >
      </Grid>
    </Grid >
  );
}
