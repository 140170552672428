import profile1 from './profile1.jpg'
import profile2 from './profile2.jpg'
import profile3 from './profile3.jpg'
import profile4 from './profile4.jpg'
import profile5 from './profile5.jpg'
import profile6 from './profile6.jpg'
import profile7 from './profile7.jpg'
import profile8 from './profile8.jpg'
import profile9 from './profile9.jpg'
import profile10 from './profile10.jpg'
import profile11 from './profile11.jpg'


const equipo = [
  {
    descripcion:
      'Aficionado a la musica y los deportes, me gusta compartir con la familia y amigos.',
    foto: profile1,
    nombre: 'Francisco Inostroza B.',
    cargo: 'Fundador y Gerente General',
  },
  {
    descripcion:
      'Aficionado a la musica y los deportes, me gusta compartir con la familia y amigos.',
    foto: profile2,
    nombre: 'Lautaro Moreira A',
    cargo: 'Sub-Gerente de Informática',
  },
  {
    descripcion:
      'Ing. Naval con mención en Arquitectura y Transporte marítimo, Ing. Civil Industrial en formación, siempre dispuesto a aprender me encuentro en búsqueda constante de nuevos desafíos.',
    foto: profile3,
    nombre: 'Angel Monje C.',
    cargo: 'Sub-Gerente de operaciones',
  },
  {
    descripcion:
      'Contador Auditor, Magister en Derecho Tributario. Amante de la musica y la tecnología, en busca de la felicidad.',
    foto: profile5,
    nombre: 'Erwin Soto M.',
    cargo: 'Partner',
  },
  {
    descripcion:
      'Ingeniero de Ejecución en Informática. Desarrollador de aplicaciones de escritorio y Desarrollador Web,  me gusta ver una buena serie, fanático de la tecnología y el fútbol.',
    foto: profile6,
    nombre: 'Francisco Díaz J.',
    cargo: 'Full Stack Developer',
  },
  // {
  //   descripcion:
  //     'Ingeniero civil en informatica. Asumo con resposabilidad los restos y metas propuestas, me encanta el deporte y recorrer cada rincon del mundo.',
  //   foto: profile7,
  //   nombre: 'Sebastián Inostroza',
  //   cargo: 'Full Stack Developer',
  // },
  {
    descripcion:
      'Aficionado a la musica y los deportes, me gusta compartir con la familia y amigos.',
    foto: profile8,
    nombre: 'Alejandro Díaz N.',
    cargo: 'Soporte Ti',
  },
  {
    descripcion:
      'Aficionado a la musica y los deportes, me gusta compartir con la familia y amigos.',
    foto: profile9,
    nombre: 'Aquiles Almonacid A.',
    cargo: 'Soporte Ti',
  },
  {
    descripcion:
      'Aficionado a la musica y los deportes, me gusta compartir con la familia y amigos.',
    foto: profile10,
    nombre: 'Ricardo Villarruel A.',
    cargo: 'Soporte Ti',
  },
  {
    descripcion:
      'Ingeniero informatico, Desarrollador web. En constante crecimiento profesional, me gustan las series y las peliculas.',
    foto: profile11,
    nombre: 'Franco Gamonal M.',
    cargo: 'Programador Full Stack',
  },
]

export default equipo
