import React, { useState } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl' // ONLY USE v5.2.11
import { IconButton } from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa'

const token = process.env.REACT_APP_MAPGL_API_KEY

export default function Map() {
  const [viewport] = useState({
    latitude: -40.576169,
    longitude: -73.130736,
    zoom: 16,
    width: '100%',
    height: '50vh',
    size: 20,
  })

  function Pin() {
    return (
      <IconButton
        isRound="true"
        backgroundColor="#BEE625"
        icon={<FaMapMarkerAlt color="#262626" />}
      ></IconButton>
    )
  }

  return (
    <ReactMapGL
      mapboxApiAccessToken={token}
      {...viewport}
      mapStyle={'mapbox://styles/mapbox/light-v10'}
      // onViewportChange={(viewport) => setViewport(viewport)}
      scrollZoom={false}
    >
      <Marker key={`marker-${1}`} latitude={viewport.latitude} longitude={viewport.longitude}>
        <Pin />
      </Marker>
    </ReactMapGL>
  )
}
