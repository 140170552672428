import React from "react";
import {
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import PDV from "../../../administracion/jsons_images/Productos/PDV/PDV"

export default function Header() {
  return (
    <Grid
      bgImage={{
        md: PDV.bg_w,
        base: PDV.bg_m
      }}
      // bgSize="100% 100%"
      bgSize={"cover"}
      backgroundRepeat='no-repeat'
      pl={{ md: "15%", base: "7%" }}
      pt={{ base: '500px', md: '200px' }}
      pb={{ base: '50px', md: '200px' }}
    >
      <GridItem
        display="inline"
        float="center"
      >
        <Text fontSize="40px" fontWeight={"bold"}>
          {PDV.titulo1}
        </Text>
        <Text as="span" fontSize="40px" fontWeight={"bold"}>
          {PDV.titulo2}
        </Text>
        <Text fontSize="40px" fontWeight={"bold"} as={"mark"} bgColor="#B2D237">
          {PDV.titulo3}
        </Text>
      </GridItem>
      <GridItem
        w={{ base: "80%", lg: "25%" }}
      >
        <Text fontSize="12px">
          {parse(PDV.subtitulo)}
        </Text>
      </GridItem>
    </Grid >
  );
}
