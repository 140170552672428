import React from "react";
import {
  Grid,
} from '@chakra-ui/react';

import Contactanos from "../../components/Contacto/Contactanos"
// import Videollamada from "../../components/Contacto/Videollamada"
import Mapa from "../../components/Contacto/Mapa"

export default function Home() {

  return (
    <Grid >
      <Contactanos />
      {/* <Videollamada /> */}
      <Mapa />
    </Grid >
  );
}
