import dif01 from './dif01.svg'
import dif02 from './dif02.svg'
import dif03 from './dif03.svg'

const diferencia = {
  titulo1: 'Marcamos',
  titulo2: 'la diferencia',
  subtitulo: 'Le damos el valor a tu información para facilitar tus decisiones.',
  Trayectoria: {
    imagen: dif01,
    descripcion:
      'Con casi 10 años de experiencia hemos desarrollado el software definitivo para contadores y empresas, manteniendo una mejora continua a lo largo del tiempo.',
  },
  Experiencia: {
    imagen: dif02,
    descripcion:
      'Cada uno de nuestros software fue diseñado por expertos del rubro contable tributario y puesto a prueba bajo estrictos controles de calidad, logrando que cada uno de nuestros productos sea intuitivo y eficiente.',
  },
  Soporte: {
    imagen: dif03,
    descripcion:
      'Nuestro equipo de soporte consolidado te guiara en el proceso de implementación y estará contigo cada vez que necesites ayuda.',
  },
}

export default diferencia
