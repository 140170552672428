import React, { useState, useEffect } from "react";
import {
  Grid,
  GridItem,
  Image,
  Text,
  ScaleFade,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import ERP from "../../../administracion/jsons_images/Productos/ERP/ERP"

export default function Modulos() {
  const [isMobile, setIsMobile] = useState(false)
  const [showLess, setShowLess] = useState({
    0: !isMobile ? false : true,
    1: !isMobile ? false : true,
    2: !isMobile ? false : true,
    3: !isMobile ? false : true,
    4: !isMobile ? false : true,
    5: !isMobile ? false : true,
    6: !isMobile ? false : true,
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const handleViewMore = (position) => {
    let newValuve = showLess[position];
    setShowLess({
      ...showLess,
      [position]: !newValuve
    })
  }

  return (
    <Grid
      px={{ md: "15%", base: "5%" }}
      pt="25px"
    >
      <Grid
        display="inline"
        float="center"
        m="auto"
      >
        <Text as="span" fontSize="30px" fontWeight={800}>
          {ERP.modulos.titulo1}
        </Text>
        <Text fontSize="30px" fontWeight={800} pl="5px" pr="30px" as={"mark"} bgColor="#BEE625">
          {ERP.modulos.titulo2}
        </Text>
      </Grid>
      <Grid
        pt="5%"
        templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(11, 1fr)", sm: "repeat(11, 1fr)", md: "repeat(7, 1fr)", lg: "repeat(7, 1fr)" }}
        gap={3}
      >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[0].imagen} boxSize="200px" m="auto" alt="mod01" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[0].titulo}
          </Text>
          <Text fontSize="12px">
            {showLess[0] ? parse(ERP.modulos.contenido[0].texto.slice(0, 200)) : parse(ERP.modulos.contenido[0].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(0)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ md: 'none' }}
          >
            {showLess[0] ? "..." : "ver menos"}
          </Text>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          display={{ md: 'none' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[1].imagen} boxSize="200px" m="auto" alt="mod02" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[1].titulo}
          </Text>
          <Text fontSize="12px" >
            {showLess[1] ? parse(ERP.modulos.contenido[1].texto.slice(0, 200)) : parse(ERP.modulos.contenido[1].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(1)}
            fontWeight="bold"
            pl="4px"
            display={{ md: 'none' }}
          >
            {showLess[1] ? "..." : "ver menos"}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          display={{ base: "none", md: "block" }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[1].imagen} boxSize="200px" m="auto" alt="mod02" />
          </ScaleFade>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[2].imagen} boxSize="200px" m="auto" alt="mod03" />
          </ScaleFade>

        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[2].titulo}
          </Text>
          <Text fontSize="12px" >
            {showLess[2] ? parse(ERP.modulos.contenido[2].texto.slice(0, 200)) : parse(ERP.modulos.contenido[2].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(2)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ md: "none" }}
          >
            {showLess[2] ? "..." : "ver menos"}
          </Text>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          display={{ md: "none" }}

        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={ERP.modulos.contenido[3].imagen} boxSize="200px" m="auto" alt="mod04" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[3].titulo}
          </Text>
          <Text fontSize="12px">
            {showLess[3] ? parse(ERP.modulos.contenido[3].texto.slice(0, 200)) : parse(ERP.modulos.contenido[3].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(3)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ md: "none" }}

          >
            {showLess[3] ? "..." : "ver menos"}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          display={{ base: 'none', md: "block" }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[3].imagen} boxSize="200px" m="auto" alt="mod04" />
          </ScaleFade>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>

            <Image src={ERP.modulos.contenido[4].imagen} boxSize="200px" m="auto" alt="mod05" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[4].titulo}
          </Text>
          <Text fontSize="12px" >
            {showLess[4] ? parse(ERP.modulos.contenido[4].texto.slice(0, 200)) : parse(ERP.modulos.contenido[4].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(4)}
            fontWeight="bold"
            pl="4px"
            display={{ md: "none" }}
          >
            {showLess[4] ? "..." : "ver menos"}
          </Text>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          display={{ md: "none" }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[5].imagen} boxSize="200px" m="auto" alt="mod06" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[5].titulo}
          </Text>
          <Text fontSize="12px" >
            {showLess[5] ? parse(ERP.modulos.contenido[5].texto.slice(0, 200)) : parse(ERP.modulos.contenido[5].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(5)}
            fontWeight="bold"
            pl="4px"
            display={{ md: "none" }}
          >
            {showLess[5] ? "..." : "ver menos"}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          display={{ base: "none", md: "block" }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[5].imagen} boxSize="200px" m="auto" alt="mod06" />
          </ScaleFade>
        </GridItem>


        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={ERP.modulos.contenido[6].imagen} boxSize="200px" m="auto" alt="mod07" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulos.contenido[6].titulo}
          </Text>
          <Text fontSize="12px">
            {showLess[6] ? parse(ERP.modulos.contenido[6].texto.slice(0, 200)) : parse(ERP.modulos.contenido[6].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(6)}
            fontWeight="bold"
            pl="4px"
            display={{ md: "none" }}
          >
            {showLess[6] ? "..." : "ver menos"}
          </Text>
        </GridItem>
      </Grid>
    </Grid >
  );
}
