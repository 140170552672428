import { Box, Button, Grid, GridItem, Image, ScaleFade, Text } from '@chakra-ui/react';
import React from 'react';
import circleImg from '../../administracion/jsons_images/Webinar/Banner/circle_img_jose_fajardo.jpg';

import { useNavigate } from 'react-router-dom';
function Webinar() {
  const navigate = useNavigate();
  
  //   navigate to google onclick
  // navigation redirect to another page

  return (
    // create 1 row and 3 columns
    <Grid
      // templateRows="repeat(1, 1fr)"
      // templateColumns="repeat(3, 1fr)"
      
    // use all width if movile 
      templateColumns={{ base: 'repeat(1, 1fr)',sm:'repeat(2, 1fr)', md:'repeat(2, 1fr)',lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
    mt={{ base: '15%',sm: '15%',md: '0%',lg: '0%', xl:'0%' }}
    // mt={{base: '15%',sm: '15%' }}
    
      px={{ lg: '10%', base: '5%' }}
      gap={4}
      w="100%"
      h="100%"
      bg="white"
      p="2rem"
      color="black"
      //   bgImage="url('https://res.cloudinary.com/dx9dnqzaj/image/upload/v1623952027/
      >
      <GridItem
        rowSpan={1}
        colSpan={1}
        bg="white"
        p="2rem"
        color="black"
          >
        <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
        <Box
          as="img"
          src={circleImg}
          alt="Segun Adebayo"
          borderRadius="full"        
          boxSize={{ base: '100%', sm: '100%', md: '100%', lg: '100%',xl: '100%' }}
          m="auto"
        />
      </ScaleFade>
          </GridItem>


      <GridItem
        rowSpan={1}
        colSpan={{ base: 1, lg: 1, xl: 2 }}
        bg="white"        
        p={{ base: '0rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem' }}
        color="black"        
        alignSelf="center"
          >
        <Text fontSize="2xl" fontWeight="bold" pb={3}>
        ¿Quieres ver el ultimo webinar que realizamos con Jose Fajardo?          
        </Text>
        
        <Text fontSize="xl" pb={3}  >
          Los temas fueron:
        </Text>
        <Text fontSize="xl" pb={3}>
          Hitos y cambios más importantes en esta operación renta 2023.
        </Text>
        <Text fontSize="xl" >
          Demostración y uso del software tributario Audisoft.
        </Text>
        <Box
        // center text
        // ps="20px"
// center button if movile
        textAlign={{ base: 'center', sm: 'center', md: 'left', lg: 'left', xl: 'left' }}
        >
          <Button
            mt={5}
            backgroundColor="#A5C918"
            paddingY={10}
            paddingX={10}
            color="white"
            borderRadius="20px"
            fontSize={{ base: 'xl', sm: 'xl', md: '2xl', lg: '2xl' }}
            //   onclick redirect to google
            onClick={() =>
              window.open(
                'https://www.youtube.com/watch?v=RAh-owSlEDA&ab_channel=AudisoftSpa',
                '_blank'
              )
            }
            _hover={{
              textDecoration: 'none',
              bg: '#7a9413',
            }}
          >
            ¡ VER VIDEO !
          </Button>
        </Box>

          </GridItem>
      
      
      </Grid>




  );
}

export default Webinar;
