import React, { useState, useEffect } from "react";
import {
  Grid,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import lottie from "lottie-web";

import Cantidad from "../../Shared/Simular/Cantidad"
import empresas from "../../../administracion/jsons_images/Simular/animations/empresas.json"
import contador from "../../../administracion/jsons_images/Simular/animations/contador.json"

export default function QP_SW_CONTABLE({ ...props }) {
  const { answers, setAnswers, currentQuestion, setCurrentQuestion } = props
  const [typeContable, setTypeContable] = useState(null);

  useEffect(() => {
    if (typeContable === "QPWC_1") {
      setAnswers({
        ...answers,
        QPC_USUARIOS: {
          pregunta: currentQuestion.subAnswer.question,
          respuesta: "0"
        }
      })
    } else {
      delete answers.QPC_USUARIOS
    }
  }, [typeContable]);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#empresas-an"),
      animationData: empresas
    });
    lottie.loadAnimation({
      container: document.querySelector("#contador-an"),
      animationData: contador
    });
  }, []);

  const handleSetType = (option) => {
    setCurrentQuestion({
      ...currentQuestion,
      answer: currentQuestion.answers[option].nextQuestion
    })
    setTypeContable(option)
    setAnswers({
      ...answers,
      [currentQuestion.id]: {
        pregunta: currentQuestion.question,
        respuesta: currentQuestion.answers[option].value
      }
    })
  }

  return (
    <>
      <Grid m="auto" pt="10%" >
        <Text fontSize="16px" fontWeight="bold">
          {currentQuestion.question}
        </Text>
      </Grid>

      <Grid
        display="flex"
        gap={5}
        m="auto"
        pt="3%"
      >
        <Button
          bg="transparent"
          border="2px"
          borderRadius="xl"
          borderColor={typeContable === "QPWC_1" ? "#BEE625" : "#EEEFED"}
          onClick={() => handleSetType("QPWC_1")}
          h="100%"
          py="5%"
          px="25%"
        >
          <HStack
            spacing={8}
            alignItems={'center'}
          >
            <Grid>
              <div id="contador-an" style={{ width: 150, height: 150, paddingBottom: "14%" }} />
              <Text fontSize="12px" fontWeight={100} color="#A5C918" >Quiero un software</Text>
              <Text fontSize="12px" fontWeight="bold" color="#A5C918">para contador</Text>
            </Grid>
          </HStack>
        </Button>
        <Button
          bg="white"
          border="2px"
          borderRadius="xl"
          borderColor={typeContable === "QPWC_2" ? "#BEE625" : "#EEEFED"}
          onClick={() => handleSetType("QPWC_2")}
          h="100%"
          py="5%"
          px="25%"
        >
          <HStack
            spacing={8}
            alignItems={'center'}
          >
            <Grid>
              <div id="empresas-an" style={{ width: 150, height: 150, paddingBottom: "14%" }} />
              <Text fontSize="12px" fontWeight={100} color="#A5C918" >Quiero un software</Text>
              <Text fontSize="12px" fontWeight="bold" color="#A5C918">para empresas</Text>
            </Grid>

          </HStack>
        </Button>

      </Grid>

      {/* RECICLABLE */}
      {typeContable === "QPWC_1" &&
        <Cantidad
          title={currentQuestion.subAnswer.question}
          subtitle={currentQuestion.subAnswer.subtitle}
          id={currentQuestion.subAnswer.id}
          answers={answers}
          setAnswers={setAnswers}
          defVal={0}
          min={0}
          max={24}
        />
      }
    </>
  );
}
