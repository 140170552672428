import { useEffect} from 'react'

export default function Chat() {
  useEffect(() => {
    (function loadHubSpot() {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://js-eu1.hs-scripts.com/25050920.js";
      s.async = true;
      d.getElementsByTagName("body")[0].appendChild(s);
    })();
  }, [])

  return (
    <>
    </>
  )
}
