import React from "react";
import {
  Grid,
  Text,
} from '@chakra-ui/react';
import lottie from "lottie-web";

import Cantidad from "../Shared/Simular/Cantidad"
import SiNo from "../Shared/Simular/SiNo"
import lre from "../../administracion/jsons_images/Simular/animations/LRE.json"

export default function LRE({ ...props }) {
  const { answers, setAnswers, currentQuestion, setCurrentQuestion } = props

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#lre-an"),
      animationData: lre
    });

  }, []);

  return (
    <>
      <Grid m="auto">
        <div id="lre-an" style={{ width: 150, height: 150 }} />
      </Grid>

      <Grid m="auto" pt="5%" w={{ md: "80%", base: "90%" }}>
        <Text fontSize="16px" fontWeight="bold" textAlign="center">
          {currentQuestion.question}
        </Text>
      </Grid>
      <Grid m="auto" w={{ md: "50%", base: "90%" }}>
        <Text fontSize="12px" textAlign="center">
          {currentQuestion.subtitle}
        </Text>
      </Grid>

      {/* RECICLABLE */}
      <SiNo
        answers={answers}
        setAnswers={setAnswers}
        id={currentQuestion.id}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
      />

      {/* RECICLABLE */}
      {currentQuestion.answer === "Sí" &&
        <>
          {Object.keys(currentQuestion.subAnswer).map(key => (
            <Cantidad
              key={key}
              title={currentQuestion.subAnswer[key].question}
              subtitle={currentQuestion.subAnswer[key].subtitle}
              id={currentQuestion.subAnswer[key].id}
              answers={answers}
              setAnswers={setAnswers}
              defVal={0}
              min={0}
              max={24}
            />
          ))}
        </>
      }
    </ >
  );
}
