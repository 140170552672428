import { Box, Grid, ScaleFade, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import pasos from '../../../administracion/jsons_images/Productos/Contador/Pasos/pasos';

function Item({ image, title, description }) {
    return (
        <Box
            maxW="sm"
            borderRadius="lg"
            overflow="hidden"
            p="5%"
            m="auto"
            textAlign="center"
            h="100%"
        >
            <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Box
                    as="img"
                    src={image}
                    alt="Segun Adebayo"
                    borderRadius="full"
                    boxSize="150px"
                    m="auto"
                />
            </ScaleFade>

            <Box mt="1" pb={2} as="h4" fontWeight="bold" fontSize={'xl'}>
                {title}
            </Box>
            <Box>{description}</Box>
        </Box>
    );
}

function Pasos() {
    return (
        <Grid
            id="Pasos"
            px={{ lg: '10%', base: '5%' }}
            // pt="4%"
            // pt 4% only in mobile
            pt={{ base: '4%', sm: '4%', md: '0%', lg: '0%', xl: '0%' }}
        // backgroundColor={'#A5C918'}
        >
            <Grid display="inline" float="center" m="auto" pb="5%">
                <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                    {pasos.map((paso, index) => (
                        <Item
                            key={index}
                            image={paso.imagen}
                            title={paso.titulo}
                            description={paso.descripcion}
                        />
                    ))}
                </SimpleGrid>
            </Grid>
        </Grid>
    );
}

export default Pasos;
