import React from "react";
import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Grid,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons'

export default function ModalSuccess({ ...props }) {
  const { isOpen, setIsOpen } = props

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(false)
    }, 8000);
  }, [setIsOpen]);

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
        </ModalHeader>
        <ModalBody>
          <>
            <Grid m="auto">
              <CheckCircleIcon color="#A5C918" fontSize='9xl' m="auto" />
            </Grid>
          </>
          <Grid>
            <Grid textAlign="center" gap={3} mb="15%">
              <Text fontSize="30px" fontWeight="bold" color="#A5C918">
                ¡Gracias por enviar el formulario!
              </Text>
              <Text fontSize="12px">
                Hemos recibido tu formulario de contacto. Uno de nuestros ejecutivos se pondrá en contacto contigo a la brevedad.
              </Text>
            </Grid>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal >
  );
}
