import React, { useEffect } from "react";
import {
  Grid,
  Text,
} from '@chakra-ui/react';
import lottie from "lottie-web";

import SiNo from "../../Shared/Simular/SiNo"
import pc2 from "../../../administracion/jsons_images/Simular/animations/pc2.json"

export default function QP_CONTABLE({ ...props }) {
  const {
    answers,
    setAnswers,
    currentQuestion,
    setCurrentQuestion,
    progressQ,
    setProgressQ
  } = props

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#pc2-an"),
      animationData: pc2
    });
    setProgressQ({
      ...progressQ,
      total: 2
    })
  }, []);

  useEffect(() => {
    if (currentQuestion.answer === "Sí") {
      setProgressQ({
        ...progressQ,
        total: 6
      })
    } else {
      setProgressQ({
        ...progressQ,
        total: 2
      })
    }
  }, [currentQuestion.answer]);

  return (
    <>
      <Grid m="auto" pt="10%">
        <Grid m="auto">
          <div id="pc2-an" style={{ width: 150, height: 150, paddingBottom: "14%" }} />
        </Grid>
        <Grid textAlign="center">
          <Text fontSize="16px" fontWeight="bold">
            {currentQuestion.question}
          </Text>
          <Text fontSize="12px" >
            {currentQuestion.subtitle}
          </Text>
          <SiNo
            answers={answers}
            setAnswers={setAnswers}
            id={currentQuestion.id}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        </Grid>
      </Grid>
    </>
  );
}
