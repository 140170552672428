import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
} from '@chakra-ui/react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import productividad from "../../administracion/jsons_images/Home/Productividad/productividad"

export default function Productividad() {
  const { ref, inView } = useInView();
  const [onView, setOnView] = useState(false);

  useEffect(() => {
    if (inView) {
      setOnView(true)
    }
  }, [inView]);


  return (
    <Grid py={{ base: "15%", md: "3%" }}>
      <Grid
        templateColumns={{ base: "repeat(3, 1fr)", sm: "repeat(3, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(4, 1fr)" }}
        templateRows={{ base: "repeat(3)", sm: "repeat(3)", md: "repeat(2)", lg: "repeat(2)" }}
        background={{ lg: "linear-gradient(90deg, rgba(245,245,247,1) 42%, rgba(248,253,208,1) 92%)", base: "linear-gradient(3deg, rgba(245,245,247,1) 32%, rgba(248,253,208,1) 100%)" }}
        borderRadius={{ base: "0%", lg: "0px 264px 264px 0px" }}
        mr={{ base: "0%", lg: "10%" }}
        px={{ base: "0%", md: "15%" }}
        py={{ md: "3%", base: "10%" }}
      >
        <GridItem
          colSpan={{ base: 4, sm: 4, md: 4, lg: 4 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          pl={{ md: "30%", base: "5%" }}
          pr={{ md: "8%", base: "0%" }}

          textAlign="right"
        >
          <Text fontSize="30px" pr="30px" >
            {productividad.titulo1}
          </Text>
          <Text fontSize="30px" fontWeight={"bold"} pr="30px">
            {productividad.titulo2}
          </Text>
          <Text as={"span"} fontSize="30px" fontWeight={"bold"} backgroundColor="#BEE625" pl="30px" pr="30px">
            {productividad.titulo3}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 4, sm: 4, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          pt={{ md: "0%", base: "5%" }}
        >
          <Box textAlign="center" >
            <Image src={productividad.edificio} boxSize='200px' display="block" margin="auto" alt="edificio" />
            <Text fontSize={{ md: "73px", base: "30px" }} fontWeight={600} color="#A5C918" >
              <div ref={ref}>
                {onView ?
                  <CountUp
                    start={0}
                    end={productividad.indicadores.empresas}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                  :
                  <CountUp
                    start={0}
                    end={0}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                }
              </div>
            </Text>
            <Text fontSize="16px" w={"80%"} m="auto">Empresas usando Audisoft</Text>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          pt={{ md: "50%", base: "4%" }}
        >
          <Box textAlign="center" >
            <Image src={productividad.usuario} boxSize='100px' display="block" margin="auto" alt="usuario" />
            <Text fontSize="22px" fontWeight={600} color="#A5C918">
              <div ref={ref}>
                {onView ?
                  <CountUp
                    start={0}
                    end={productividad.indicadores.usuarios}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                  :
                  <CountUp
                    start={0}
                    end={0}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                }
              </div>
            </Text>
            <Text fontSize="10px">Usuarios activos</Text>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          pt={{ md: "50%", base: "4%" }}
        >
          <Box textAlign="center" >
            <Image src={productividad.doc} boxSize='100px' display="block" margin="auto" alt="documento" />
            <Text fontSize="22px" fontWeight={600} color="#A5C918" >
              <div ref={ref}>
                {onView ?
                  <CountUp
                    start={0}
                    end={productividad.indicadores.DTE}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                  :
                  <CountUp
                    start={0}
                    end={0}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                }
              </div>
            </Text>
            <Text fontSize="10px">DTE emitidos por día</Text>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          pt={{ md: "50%", base: "4%" }}
        >
          <Box textAlign="center" >
            <Image src={productividad.support} boxSize='100px'display="block" margin="auto" alt="soporte" />
            <Text fontSize="22px" fontWeight={600} color="#A5C918" >
              <div ref={ref}>
                {onView ?
                  <CountUp
                    start={0}
                    end={productividad.indicadores.calificacion}
                    duration={1.5}
                    decimal={3}
                    suffix="%"
                  />
                  :
                  <CountUp
                    start={0}
                    end={0}
                    duration={1.5}
                    decimals={3}
                    decimal="."
                  />
                }
              </div>
            </Text>
            <Text fontSize="10px" w={{ md: "50%", base: "40%" }} m="auto">Calificó a soporte con 5 estrellas</Text>
          </Box>
        </GridItem>
      </Grid >
    </Grid >
  );
}
