const preguntasFrecuentes = [
  {
    pregunta: '¿Se puede importar la información desde otro sistema contable?',
    respuesta: 'Si, tenemos opciones de importación masiva a través de planillas electrónicas.',
  },
  {
    pregunta: '¿Cómo puedo saber si mi contabilidad está al día?',
    respuesta:
      'Nuestro sistema de gestión contable te permite llevar las estadísticas de tus controles de cierre, con esto podrás controlar tu proceso contable.',
  },
  {
    pregunta: '¿Si tengo una empresa contable existe algún descuento al activar varios usuarios?',
    respuesta:
      'Si, existe un descuento progresivo muy conveniente para empresas contables, puedes comprobarlo simulando tu cotización.',
  },
]

export default preguntasFrecuentes
