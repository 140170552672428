// IMAGENES BACKGROUND
import bg1_m from './slide1_m_bg.png'
import bg1_w from './slide1_w_bg.png'
import bg2_m from './slide2_m_bg.png'
import bg2_w from './slide2_w_bg.png'
import bg3_m from './slide3_m_bg.png'
import bg3_w from './slide3_w_bg.png'
import bg4_m from './slide4_m_bg.png'
import bg4_w from './slide4_w_bg.png'

// VIDEOS
/* import video1 from './audisoft3.mov'
import video2 from './audisoft4.mov'
import video3 from './audisoft5.mov'
import video4 from './audisoft6.mov'
 */
const slider = [
  {
    bg_m: bg1_m,
    bg_w: bg1_w,
    titulo1: 'Gestiona con ',
    titulo2: 'Audisoft',
    subtitulo1: 'Y toma el control ahora',
    subtitulo2: 'Cuidamos tu tiempo, aseguramos tu gestión, juntos cumplimos.',
    video: '',
  },
  {
    bg_m: bg2_m,
    bg_w: bg2_w,
    titulo1: 'Conoce nuestro',
    titulo2: 'Punto de venta',
    subtitulo1: '',
    subtitulo2:
      'Con nuestro sistema <strong> 100% web </strong> puedes Integrar sucursales, bodegas e inventarios, para emitir tus boletas y facturas.',
    video: '',
  },
  {
    bg_m: bg3_m,
    bg_w: bg3_w,
    titulo1: 'EL ERP que',
    titulo2: 'estabas buscando',
    subtitulo1: 'Maneja cada aspecto de tu empresa en un click.',
    subtitulo2: 'Desde finanzas, impuestos, emisión de documentos, hasta liquidaciones de sueldo y más.',
    video: '',
  },
  // {
  //   bg_m: bg4_m,
  //   bg_w: bg4_w,
  //   titulo1: 'Módulo',
  //   titulo2: 'Empresas automotrices',
  //   subtitulo1: 'Próximamente, ',
  //   subtitulo2: 'implementación en nuestro software ERP.',
  //   video: '',
  // },
]

export default slider
