import React from 'react'
import { Image } from '@chakra-ui/react'

import contacto from "../../administracion/jsons_images/Shared/Contacto/contacto"
// import whatsapp_logo from "../../assets/images/Navigation/whatsapp_logo.svg"
import './navBar.css'

export default function Whatsapp() {
  const openWhatsapp = () => {
    window.open(`https://wa.me/${contacto.telefono}`)
  }

  return (
    <div
      className={"float"}
      style={{ bottom: '10px' }}
    >
      <button onClick={openWhatsapp}>
        <Image src={contacto.whatsapp.logo} alt="whatsapp_logo" />
      </button>
    </div>
  )
}
