import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import Home from './pages/Home/Home'
import Nosotros from './pages/Nosotros/Nosotros'
import Planes from './pages/Planes/Planes'
import Simular from './pages/Simular/Simular'
import Contacto from './pages/Contacto/Contacto'

import ERP from './pages/Productos/ERP'
import PDV from './pages/Productos/PDV'
import Contador from './pages/Productos/Contador'
import Tributario from './pages/Productos/Tributario'

export default function MainRoutes() {
  const NotMatch = () => {
    return <Navigate to="/" />
  }

  return (
    <Routes>
      <Route path="/" index element={<Home/>} />
      <Route path="/Nosotros"  element={<Nosotros/>} />
      <Route path="/Productos/ERP"  element={<ERP/>} />
      <Route path="/Productos/PDV"  element={<PDV/>} />
      <Route path="/Productos/contador"  element={<Contador/>} />
      <Route path="/Productos/tributario"  element={<Tributario/>} />
      <Route path="/Planes"  element={<Planes/>} />
      <Route path="/Simular-cotizacion"  element={<Simular/>} />
      <Route path="/Contacto"  element={<Contacto/>} />
    </Routes>
  )
}
