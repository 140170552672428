import {
  Grid,
} from '@chakra-ui/react';
import React from "react";

import Webinar from "../../components/Home/Webinar";
import DeclaracionesJuradas from "../../components/Productos/Contador/DeclaracionesJuradas";
import Pasos from "../../components/Productos/Contador/Pasos";
import Header from "../../components/Productos/Tributario/Header";
// import Contactanos from "../../components/Shared/Contactanos"

export default function Tributario() {

  return (
    <Grid>
      <Header />
      <Webinar />
      {/* <Modulos /> */}
      {/* <Otros /> */}
      <DeclaracionesJuradas />
      <Pasos />

      {/* <Contactanos /> */}
    </Grid >
  );
}
