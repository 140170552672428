const contador = {
  basic: {
    titulo: 'Contador Basic',
    uf: '3 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '2,4 UF',
    perYear: 'Los primeros 12 meses',
    contenido: [
      '3 Usuarios',
      'Empresas Ilimitadas',
      'Contabilidad',
      'Remuneraciones',
      'IVA',
      'Financiero',
      'Software tributario básico "declaraciones juradas"'
    ],
  },
  full: {
    titulo: 'Contador Full',
    uf: '4,2 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '3,4 UF',
    perYear: 'Los primeros 12 meses',
    contenido: [
      '3 Usuarios',
      'Empresas Ilimitadas',
      'Contabilidad',
      'Remuneraciones',
      'IVA',
      'Financiero',
      '1 Empresa con emisión de factura',
      'Software Audisoft Tributario con 1 usuario y empresas ilimitadas',
      'Software tributario básico "declaraciones juradas"'
    ],
  },
  expert: {
    titulo: 'Contador Expert',
    uf: '4,6 UF',
    title: 'Mensual',
    oferta: 'Mejor oferta 20% descuento',
    valor: '3,6 UF',
    perYear: 'Los primeros 12 meses',
    contenido: [
      '3 Usuarios',
      'Empresas Ilimitadas',
      'Contabilidad',
      'Remuneraciones',
      'IVA',
      'Financiero',
      '1 Empresa con emisión de factura',
      '1 Empresa con LRE',
      'Software tributario básico "declaraciones juradas"'
    ],
  },
}

export default contador
