import React from "react";
import {
  Grid,
} from '@chakra-ui/react';
import lottie from "lottie-web";

import Cantidad from "../Shared/Simular/Cantidad"
import pc1 from "../../administracion/jsons_images/Simular/animations/pc1.json"

export default function Q_EMPRESA({ ...props }) {
  const { answers, setAnswers, currentQuestion } = props

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#pc1-an"),
      animationData: pc1
    });
  }, []);

  return (
    <>
      <Grid m="auto">
        <div id="pc1-an" style={{ width: 150, height: 150 }} />
      </Grid>

      {Object.keys(currentQuestion.subAnswer).map(key => (
        <Cantidad
          key={key}
          title={currentQuestion.subAnswer[key].question}
          subtitle={currentQuestion.subAnswer[key].subtitle}
          id={currentQuestion.subAnswer[key].id}
          answers={answers}
          setAnswers={setAnswers}
          defVal={0}
          min={0}
          max={24}
        />
      ))}
    </>
  );
}
