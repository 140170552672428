import { Grid, GridItem, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';
import React from 'react';
import './slider.css';


import Contador from "../../../administracion/jsons_images/Productos/Contador/Contador";

export default function Header() {
  return (
    <Grid
      bgImage={{
        md: Contador.bg_w,
        base: Contador.bg_m
      }}
      // bgSize="100% 100%"
      bgSize="cover"
      backgroundRepeat='no-repeat'
      pl={{ md: "15%", base: "7%" }}
      pt={{ base: '500px', md: '200px' }}
      pb={{ base: '50px', md: '200px' }}
    >
      <GridItem
        display="inline"
        float="center">

        <Text as="span" fontSize="40px" fontWeight={800}>
          {Contador.titulo1}
        </Text>
        <Text fontSize="40px" fontWeight={800} as={"mark"} bgColor="#B2D237">
          {Contador.titulo2}
        </Text>
        <Text fontSize="40px" fontWeight={800}>
          {Contador.titulo3}
        </Text>
      </GridItem>
      <GridItem
        w={{ base: "80%", lg: "25%" }}
      >
        <Text fontSize="12px">
          {parse(Contador.subtitulo)}
        </Text>
      </GridItem>
    </Grid>
  );
}
