import React from "react";
import {
  Grid,
  GridItem,
  Image,
  Text,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import ERP from "../../../administracion/jsons_images/Productos/ERP/ERP"

export default function Modulos() {

  return (
    <Grid
      px={{ lg: "23%", base: "5%" }}
      py="10%"
      background={"linear-gradient(175deg, rgba(245,245,247,1) 0%, rgba(252,255,239,1) 100%)"}
    >
      <Grid
        display="inline"
        float="center"
        textAlign="center"
      >
        <Text as="span" fontSize="30px" fontWeight={800} pl="15px">
          {ERP.modulosEspeciales.titulo1}
        </Text>
        <Text fontSize="30px" fontWeight={800} pr="30px" as={"mark"} bgColor="#BEE625">
          {ERP.modulosEspeciales.titulo2}
        </Text>
        <Text fontSize="30px" fontWeight={800}>
          {ERP.modulosEspeciales.titulo3}
        </Text>
        <Text fontSize="12px">
          {ERP.modulosEspeciales.subtitulo}
        </Text>
      </Grid>
      <Grid
        pt="5%"
        templateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(8, 1fr)", sm: "repeat(8, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(4, 1fr)" }}
        gap={{ base: 3, lg: 5 }}
      >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulosEspeciales.contenido[0].titulo}
          </Text>
          <Text fontSize="12px" w="90%">
            {parse(ERP.modulosEspeciales.contenido[0].texto)}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Image src={ERP.modulosEspeciales.contenido[0].imagen} m={"auto"} alt="modesp01" />
        </GridItem>

        <GridItem
          colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          display={{ base: 'none', md: 'block' }}
        >
          <Image src={ERP.modulosEspeciales.contenido[1].imagen} m={"auto"} alt="modesp02" />
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulosEspeciales.contenido[1].titulo}
          </Text>
          <Text fontSize="12px" w="90%">
            {parse(ERP.modulosEspeciales.contenido[1].texto)}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 0, lg: 0 }}
          display={{ md: 'none' }}
        >
          <Image src={ERP.modulosEspeciales.contenido[1].imagen} m={"auto"} alt="modesp02" />
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulosEspeciales.contenido[2].titulo}
          </Text>
          <Text fontSize="12px" w="90%">
            {parse(ERP.modulosEspeciales.contenido[2].texto)}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Image src={ERP.modulosEspeciales.contenido[2].imagen} m={"auto"} alt="modesp03" />
        </GridItem>

        <GridItem
          colSpan={{ base: 0, sm: 0, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, lg: 1 }}
          display={{ base: 'none', md: 'block' }}
        >
          <Image src={ERP.modulosEspeciales.contenido[3].imagen} m={"auto"} alt="modesp04" />
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {ERP.modulosEspeciales.contenido[3].titulo}
          </Text>
          <Text fontSize="12px" w="90%">
            {ERP.modulosEspeciales.contenido[3].texto}
          </Text>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 0, lg: 0 }}
          rowSpan={{ base: 1, sm: 1, md: 10, lg: 0 }}
          display={{ md: 'none' }}
        >
          <Image src={ERP.modulosEspeciales.contenido[3].imagen} m={"auto"} alt="modesp04" />
        </GridItem>
      </Grid>
    </Grid >
  );
}
