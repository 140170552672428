import bg_m from './cont_m_bg.png'
import bg_w from './cont_w_bg.png'

import mod01 from './Modulos/mod01.svg'
import mod02 from './Modulos/mod02.svg'
import mod03 from './Modulos/mod03.svg'
import mod04 from './Modulos/mod04.svg'

const contador = {
  bg_w: bg_w,
  bg_m: bg_m,
  titulo1: 'Tu conta',
  titulo2: 'bilidad',
  titulo3: 'al día',
  subtitulo:
    '<strong> Logra centralizar la operación de tu empresa; Compras, Ventas, Finanzas, Activo Fijo, Contabilidad, Recursos humanos, Impuestos mensuales entre otros, </strong> emite documentos electrónicos, cumple con las obligaciones tributarias, genera tus liquidaciones de sueldo y visualiza informes estratégicos.',
  modulos: {
    titulo1: 'Algunos de sus',
    titulo2: 'módulos',
    contenido: [
      {
        titulo: 'Contabilidad',
        texto:
          'Gestione sus movimientos financieros y disponga de una visión generalizada, los asientos contables manuales se reducirán al mínimo ya que las operaciones se traspasan y asientan automáticamente a la contabilidad financiera. Define y gestiona diferentes centros de costo, gestiona bancos y compara tu cartola con tu cuenta mayor. Administra tus activos fijos y efectúa procesos de corrección monetaria y depreciación para lograr la centralización. Visualiza informes estratégicos.',
        imagen: mod01,
      },
      {
        titulo: 'Gestión Tributaria',
        texto:
          'Cumpla con las obligaciones tributarias de una manera fácil e integrada con los demás módulos del sistema. Generación de formulario 29 del IVA, formulario 22 de Renta y las principales declaraciones juradas incluyendo los regímenes 14 A y 14 B.',
        imagen: mod02,
      },
      {
        titulo: 'Recursos Humanos',
        texto:
          'Gestione contratación de trabajadores, generación de las liquidaciones de sueldo, control de cuentas corrientes del personal, control de vacaciones, descuentos previsionales, finiquitos al mismo tiempo que se reducen los costos y las tareas que requieren mucha inversión de tiempo.',
        imagen: mod03,
      },
      {
        titulo: 'Gestión Financiera',
        texto:
          'Compare estratégicamente sus costes teórico-reales, analice posibles desviaciones y exporte a Excel en un clic si es necesario, Realice comparaciones de estados financieros patrimoniales, en valores históricos y actualizados, visualice y compare entre diferentes tiempos Informe del centro de costo, basado en los ingresos, costos y gastos. Visualice estado de flujos de efectivo, flujos de caja, índices financieros entre otros.',
        imagen: mod04,
      },
    ],
  },
}

export default contador
