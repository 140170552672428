
import React, { useState, useEffect } from "react";
import {
  Grid,
  Text,
} from '@chakra-ui/react';

import Progress from './Progress'
import Botones from "./Botones"
import Software from "./Software"
import Resultado from "./Resultado"
import ContactForm from "./ContactForm"
import Listo from "./Listo"
import LCE from "./LCE"
import LRE from "./LRE"
import TRIB from "./TRIB"
import DTE from "./DTE"
import EMPRESAS from "./EMPRESAS"
import Q_CONTABLE from "./Contador/Q_CONTABLE"
import QP_CONTABLE from "./PDV/QP_CONTABLE"
import QP_SW_CONTABLE from "./PDV/QP_SW_CONTABLE"

import Preguntas from "../../administracion/jsons_images/Simular/Preguntas.json"
import EmpresasERP from "../../administracion/jsons_images/Simular/valores/EmpresasERP.json"
import UsuariosERP from "../../administracion/jsons_images/Simular/valores/UsuariosERP.json"
import TerminalesPDV from "../../administracion/jsons_images/Simular/valores/TerminalesPDV.json"
import SWTrib from "../../administracion/jsons_images/Simular/valores/SWTrib.json"
import TerminalesLCE from "../../administracion/jsons_images/Simular/valores/TerminalesLCE.json"
import TerminalesLRE from "../../administracion/jsons_images/Simular/valores/TerminalesLRE.json"
import UsuariosCont from "../../administracion/jsons_images/Simular/valores/UsuariosCont.json"
import TerminalesGDTE from "../../administracion/jsons_images/Simular/valores/TerminalesGDTE.json"

const initState = {
  amountCompany: 0,
  amountUsers: 0,
  pdvTerminals: 0,
  DTECompanies: 0,
  LCECompanies: 0,
  LRECompanies: 0,
  usersTrib: 0,
  calculatedRate: 0,
  dsct: 0.20,
  finalRate: 0,
}

const initStateFormData = {
  contactName: "",
  contactLastName: "",
  email: "",
  phone: "",
  companyName: "",
  message: "",
}

export default function Simular() {
  const [answers, setAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [typeSoftware, setTypeSoftware] = useState(null);
  const [resultData, setResultData] = useState(initState);
  const [lastQuestion, setLastQuestion] = useState([]);
  const [formData, setFormData] = useState(initStateFormData);
  const [error, setError] = useState(false);
  const [questionStates, setQuestionStates] = useState({
    isFinished: false,
    isContactForm: false,
    isResult: false
  });
  const [progressQ, setProgressQ] = useState({
    total: 1,
    actual: 1
  });

  useEffect(() => {
    setCurrentQuestion({
      ...Preguntas.questions[Preguntas.config.INIT_Q],
      id: Preguntas.config.INIT_Q,
      answer: ""
    });
  }, []);

  useEffect(() => {
    if (currentQuestion.id === "LAST") {
      setQuestionStates({
        isFinished: false,
        isContactForm: false,
        isResult: true
      })
      if (answers.Q_TIPO.respuesta === "Necesito un software contable") {
        if (answers.Q_CONTABLE.respuesta === "Quiero un software para empresa (No contador)") {
          /*  CONT ERP */
          calcuateContERP()
          /* CONT ERP */
        } else if (answers.Q_CONTABLE.respuesta === "Quiero un software para contador") {
          /*  CONT CONT */
          calculateContCont()
          /*  CONT CONT */
        }
      }
      if (answers.Q_TIPO.respuesta === "Quiero un Punto de Venta") {
        if (answers.QP_SW_CONTABLE?.respuesta === "Quiero un software para empresa (No contador)") {
          /*  PDV ERP */
          calcuatePdvERP()
          /*  PDV ERP */
        } else if (answers.QP_SW_CONTABLE?.respuesta === "Quiero un software para contador") {
          /*  PDV CONT */
          calculatePdvCont()
          /*  PDV CONT */
        } else if (answers.QP_CONTABLE?.respuesta === "No") {
          /*  PDV NO */
          calculatePdvNo()
          /*  PDV NO */
        }
      }
    }

    /* RESUELVE PROBLEMA DE PREGUNTAS SIN QUESTION */
    if (currentQuestion.type === "EMPTY") {
      let auxObject = {}
      Object.keys(currentQuestion.subAnswer).map((key) => (
        auxObject[key] = {
          pregunta: currentQuestion.subAnswer[key].question,
          respuesta: "0"
        }
      ))
      setAnswers({
        ...answers,
        ...auxObject
      })
    }
  }, [currentQuestion]);

  /* BUTTONS */
  const handleNext = (event) => {
    event.preventDefault()
    if (currentQuestion.type === "SiNo" || currentQuestion.type === "EMPTY") {
      setCurrentQuestion({
        ...Preguntas.questions[currentQuestion.nextQuestion],
        id: currentQuestion.nextQuestion,
        answer: ""
      })
    } else {
      setCurrentQuestion({
        ...Preguntas.questions[currentQuestion.answer],
        id: currentQuestion.answer,
        answer: ""
      })
    }
    setLastQuestion([...lastQuestion, currentQuestion.id])
    setProgressQ({ ...progressQ, actual: progressQ.actual + 1 })
  }

  const handlePrev = (event) => {
    event.preventDefault()
    let auxPrev = lastQuestion;
    setCurrentQuestion({
      ...Preguntas.questions[lastQuestion[lastQuestion.length - 1]],
      id: lastQuestion[lastQuestion.length - 1],
      answer: ""
    })
    if (answers[currentQuestion.id]) {
      delete answers[currentQuestion.id]
    }
    if (currentQuestion.id === "LAST") {
      setQuestionStates({
        isFinished: false,
        isContactForm: false,
        isResult: false
      })
    }
    auxPrev.pop();
    setLastQuestion(auxPrev)
    setProgressQ({ ...progressQ, actual: progressQ.actual - 1 })
  }
  /* BUTTONS */

  /* CALCULATE VALUES  */
  const calcuatePdvERP = async () => {
    let usersTrib = "0"
    if (answers.QPEMT_EMISIONES?.respuesta === "Solo quiero emisión de declaraciones juradas (DDJJ)") {
      usersTrib = SWTrib[answers.QPEMT_EMPRESAS?.respuesta ? answers.QPEMT_EMPRESAS?.respuesta : "0"].DDJJ
    } else if (answers.QPEMT_EMISIONES?.respuesta === "Quiero emisión de DDJJ, el modulo de Renta Liquida imponible (RLI) y el modulo de declaración de impuesto a la renta (F22)") {
      usersTrib = SWTrib[answers.QPEMT_EMPRESAS?.respuesta ? answers.QPEMT_EMPRESAS?.respuesta : "0"].DDJJRLIF22
    }

    const calculatedRate =
      EmpresasERP[answers.QPEE_EMPRESAS?.respuesta ? answers.QPEE_EMPRESAS?.respuesta : "0"].porEmpresas +
      UsuariosERP[answers.QPEE_USUARIOS?.respuesta ? answers.QPEE_USUARIOS?.respuesta : "0"].porEmpresas +
      TerminalesPDV[answers.PDV?.respuesta ? answers.PDV?.respuesta : "0"].porEmpresas +
      TerminalesLCE[answers.QPEMLCE_TERMINALES?.respuesta ? answers.QPEMLCE_TERMINALES?.respuesta : "0"].porEmpresas +
      TerminalesLRE[answers.QPEMLRE_EMPRESAS?.respuesta ? answers.QPEMLRE_EMPRESAS?.respuesta : "0"].porEmpresas +
      usersTrib

    let auxObject = {
      amountCompany: answers.QPEE_EMPRESAS?.respuesta ? answers.QPEE_EMPRESAS?.respuesta : "0",
      amountUsers: answers.QPEE_USUARIOS?.respuesta ? answers.QPEE_USUARIOS?.respuesta : "0",
      pdvTerminals: answers.PDV?.respuesta ? answers.PDV?.respuesta : "0",
      LCECompanies: answers.QPEMLCE_TERMINALES?.respuesta ? answers.QPEMLCE_TERMINALES?.respuesta : "0",
      LRECompanies: answers.QPEMLRE_EMPRESAS?.respuesta ? answers.QPEMLRE_EMPRESAS?.respuesta : "0",
      usersTrib: answers.QPEMT_EMPRESAS?.respuesta ? answers.QPEMT_EMPRESAS?.respuesta : "0",
      finalRate: calculatedRate * (1 - resultData.dsct),
      calculatedRate: calculatedRate
    }
    setResultData({
      ...resultData,
      ...auxObject
    })
  }

  const calculatePdvCont = async () => {
    let usersTrib = "0"
    if (answers.QPCMTE_TERMINALES?.respuesta === "Solo quiero emisión de declaraciones juradas (DDJJ)") {
      usersTrib = SWTrib[answers.QPCMTE_TERMINALES?.respuesta ? answers.QPCMTE_TERMINALES?.respuesta : "0"].DDJJ
    } else if (answers.QPEMT_EMISIONES?.respuesta === "Quiero emisión de DDJJ, el modulo de Renta Liquida imponible (RLI) y el modulo de declaración de impuesto a la renta (F22)") {
      usersTrib = SWTrib[answers.QPCMTE_TERMINALES?.respuesta ? answers.QPCMTE_TERMINALES?.respuesta : "0"].DDJJRLIF22
    }

    const calculatedRate =
      UsuariosCont[answers.QPC_USUARIOS?.respuesta ? answers.QPC_USUARIOS?.respuesta : "0"].porEmpresas +
      TerminalesPDV[answers.PDV?.respuesta ? answers.PDV?.respuesta : "0"].porEmpresas +
      TerminalesLCE[answers.QPCLCE_TERMINALES?.respuesta ? answers.QPCLCE_TERMINALES?.respuesta : "0"].porEmpresas +
      TerminalesLRE[answers.QPCLRE_EMPRESAS?.respuesta ? answers.QPCLRE_EMPRESAS?.respuesta : "0"].porEmpresas +
      usersTrib

    let auxObject = {
      amountUsers: answers.QPC_USUARIOS?.respuesta ? answers.QPC_USUARIOS?.respuesta : "0",
      pdvTerminals: answers.PDV?.respuesta ? answers.PDV?.respuesta : "0",
      LCECompanies: answers.QPCLCE_TERMINALES?.respuesta ? answers.QPCLCE_TERMINALES?.respuesta : "0",
      LRECompanies: answers.QPCLRE_EMPRESAS?.respuesta ? answers.QPCLRE_EMPRESAS?.respuesta : "0",
      usersTrib: answers.QPCMTE_TERMINALES?.respuesta ? answers.QPCMTE_TERMINALES?.respuesta : "0",
      finalRate: calculatedRate * (1 - resultData.dsct),
      calculatedRate: calculatedRate
    }
    setResultData({
      ...resultData,
      ...auxObject
    })
  }

  const calcuateContERP = async () => {
    let usersTrib = "0"
    if (answers.QEMT_EMISIONES?.respuesta === "Solo quiero emisión de declaraciones juradas (DDJJ)") {
      usersTrib = SWTrib[answers.QEMTE_EMPRESAS?.respuesta ? answers.QEMTE_EMPRESAS?.respuesta : "0"].DDJJ
    } else {
      usersTrib = SWTrib[answers.QEMTE_EMPRESAS?.respuesta ? answers.QEMTE_EMPRESAS?.respuesta : "0"].DDJJRLIF22
    }
    const calculatedRate =
      EmpresasERP[answers.QE_EMPRESAS?.respuesta ? answers.QE_EMPRESAS?.respuesta : "0"].porEmpresas +
      UsuariosERP[answers.QE_USUARIOS?.respuesta ? answers.QE_USUARIOS?.respuesta : "0"].porEmpresas +
      TerminalesGDTE[answers.QDED_EMPRESAS?.respuesta ? answers.QDED_EMPRESAS?.respuesta : "0"].porEmpresas +
      TerminalesLCE[answers.QELCE_TERMINALES?.respuesta ? answers.QELCE_TERMINALES?.respuesta : "0"].porEmpresas +
      TerminalesLRE[answers.QEMTE_EMPRESAS?.respuesta ? answers.QEMTE_EMPRESAS?.respuesta : "0"].porEmpresas +
      usersTrib

    let auxObject = {
      amountCompany: answers.QE_EMPRESAS?.respuesta ? answers.QE_EMPRESAS?.respuesta : "0",
      amountUsers: answers.QE_USUARIOS?.respuesta ? answers.QE_USUARIOS?.respuesta : "0",
      DTECompanies: answers.QDED_EMPRESAS?.respuesta ? answers.QDED_EMPRESAS?.respuesta : "0",
      LCECompanies: answers.QELCE_TERMINALES?.respuesta ? answers.QELCE_TERMINALES?.respuesta : "0",
      LRECompanies: answers.QELRE_EMPRESAS?.respuesta ? answers.QELRE_EMPRESAS?.respuesta : "0",
      usersTrib: answers.QEMTE_EMPRESAS?.respuesta ? answers.QEMTE_EMPRESAS?.respuesta : "0",
      finalRate: calculatedRate * (1 - resultData.dsct),
      calculatedRate: calculatedRate
    }
    setResultData({
      ...resultData,
      ...auxObject,
    })
  }

  const calculateContCont = async () => {
    let usersTrib = "0"
    if (answers.QCMTE_TERMINALES?.respuesta === "Solo quiero emisión de declaraciones juradas (DDJJ)") {
      usersTrib = SWTrib[answers.QCMTE_TERMINALES?.respuesta ? answers.QCMTE_TERMINALES?.respuesta : "0"].DDJJ
    } else {
      usersTrib = SWTrib[answers.QCMTE_TERMINALES?.respuesta ? answers.QCMTE_TERMINALES?.respuesta : "0"].DDJJRLIF22
    }
    const calculatedRate =
      UsuariosCont[answers.QCE_USUARIOS?.respuesta ? answers.QCE_USUARIOS?.respuesta : "0"].porEmpresas +
      TerminalesGDTE[answers.QDCD_EMPRESAS?.respuesta ? answers.QDCD_EMPRESAS?.respuesta : "0"].porEmpresas +
      TerminalesLCE[answers.QCLCE_TERMINALES?.respuesta ? answers.QCLCE_TERMINALES?.respuesta : "0"].porEmpresas +
      TerminalesLRE[answers.QCLRE_EMPRESAS?.respuesta ? answers.QCLRE_EMPRESAS?.respuesta : "0"].porEmpresas +
      usersTrib

    let auxObject = {
      amountUsers: answers.QCE_USUARIOS?.respuesta ? answers.QCE_USUARIOS?.respuesta : "0",
      DTECompanies: answers.QDCD_EMPRESAS?.respuesta ? answers.QDCD_EMPRESAS?.respuesta : "0",
      LCECompanies: answers.QCLCE_TERMINALES?.respuesta ? answers.QCLCE_TERMINALES?.respuesta : "0",
      LRECompanies: answers.QCLRE_EMPRESAS?.respuesta ? answers.QCLRE_EMPRESAS?.respuesta : "0",
      usersTrib: answers.QCMTE_TERMINALES?.respuesta ? answers.QCMTE_TERMINALES?.respuesta : "0",
      finalRate: calculatedRate * (1 - resultData.dsct),
      calculatedRate: calculatedRate
    }
    setResultData({
      ...resultData,
      ...auxObject,
    })
  }
  const calculatePdvNo = async () => {
    const calculatedRate =
      TerminalesPDV[answers.PDV?.respuesta].porEmpresas
    let auxObject = {
      pdvTerminals: answers.PDV?.respuesta,
      finalRate: calculatedRate * (1 - resultData.dsct),
      calculatedRate: calculatedRate
    }
    setResultData({
      ...resultData,
      ...auxObject,
    })
  }
  /* CALCULATE VALUES  */

  return (
    <Grid>
      <Grid py={{ base: "30px" }}>
        <Grid m="auto" pt="10%">
          <Text fontSize="30px" fontWeight="bold" color="#A5C918">
            Simula tu cotización
          </Text>
        </Grid>

        {/* <Progress /> */}
        {progressQ.total >= progressQ.actual && <Progress progressQ={progressQ} />}

        {currentQuestion.id === "Q_TIPO" &&
          <Software
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            typeSoftware={typeSoftware}
            setTypeSoftware={setTypeSoftware}
            setProgressQ={setProgressQ}
          />
        }

        {/* CONTADOR */}
        {currentQuestion.id === "Q_CONTABLE" &&
          <Q_CONTABLE
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            progressQ={progressQ}
            setProgressQ={setProgressQ}
          />
        }

        {(currentQuestion.id === "Q_DTE_CONTADOR" ||
          currentQuestion.id == "Q_DTE_EMPRESAS"
        ) &&
          <DTE
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        }

        {(currentQuestion.id === "QC_MOD_LRE" ||
          currentQuestion.id === "QPC_MOD_LRE" ||
          currentQuestion.id === "QE_MOD_LRE" ||
          currentQuestion.id === "QPC_MOD_LRE" ||
          currentQuestion.id === "QPE_MOD_LRE"
        ) &&
          <LRE
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        }

        {(currentQuestion.id === "QC_MOD_TRIB" ||
          currentQuestion.id === "QE_MOD_TRIB" ||
          currentQuestion.id === "QPC_MOD_TRIB" ||
          currentQuestion.id === "QPE_MOD_TRIB"
        ) &&
          <TRIB
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        }

        {/* ERP PDV */}
        {currentQuestion.id === "QP_CONTABLE" &&
          <QP_CONTABLE
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            progressQ={progressQ}
            setProgressQ={setProgressQ}
          />
        }

        {currentQuestion.id === "QP_SW_CONTABLE" &&
          <QP_SW_CONTABLE
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        }

        {(currentQuestion.id === "QPC_MOD_LCE" ||
          currentQuestion.id === "QPE_MOD_LCE" ||
          currentQuestion.id === "QC_MOD_LCE" ||
          currentQuestion.id === "QE_MOD_LCE"
        ) &&
          <LCE
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
          />
        }

        {(currentQuestion.id === "QP_EMPRESA_EMPRESAS" ||
          currentQuestion.id === "QC_EMPRESA"
        ) &&
          <EMPRESAS
            answers={answers}
            setAnswers={setAnswers}
            currentQuestion={currentQuestion}
          />
        }

        {/* OTHERS */}
        {questionStates.isResult && <Resultado data={resultData} />}
        {questionStates.isContactForm && <ContactForm formData={formData} setFormData={setFormData} error={error} />}
        {questionStates.isFinished && <Listo />}

        <Botones
          currentQuestion={currentQuestion}
          handleNext={handleNext}
          handlePrev={handlePrev}
          formData={formData}
          setFormData={setFormData}
          error={error}
          setError={setError}
          initStateFormData={initStateFormData}
          resultData={resultData}
          questionStates={questionStates}
          setQuestionStates={setQuestionStates}
        />
      </Grid >
    </Grid >
  );
}
