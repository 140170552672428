import logo1 from './logo1.png'
import logo2 from './logo2.jpg'
import logo3 from './logo3.png'
import logo4 from './logo4.jpg'
import logo5 from './logo5.png'
import logo7 from './logo7.jpg'

const confian = [
  {
    logo: logo1,
    alt: 'avifel',
  },
  {
    logo: logo2,
    alt: 'udl',
  },
  {
    logo: logo3,
    alt: 'perfomaq',
  },
  {
    logo: logo4,
    alt: 'salfasur',
  },
  {
    logo: logo5,
    alt: 'forestal',
  },
  {
    logo: logo7,
    alt: 'Bedecarratz',
  },
]

export default confian
