import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useHref } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  VStack,
  Image,
  IconButton,
  Link,
  Text,
  Grid,
  Button,
  List,
  ListItem,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

import Logo from '../../administracion/logo.png'
import contacto from "../../administracion/jsons_images/Shared/Contacto/contacto"

import './navBar.css'

const Menus = [
  { title: 'Nosotros', link: '/Nosotros', id: 0 },
  {
    title: 'Productos',
    link: '',
    subNav: [
      { title: 'ERP', link: '/Productos/ERP', id: 11 },
      { title: 'Contador', link: '/Productos/Contador', id: 12 },
      { title: 'Punto de venta', link: '/Productos/PDV', id: 13 },
      { title: 'Tributario', link: '/Productos/tributario', id: 14 },
    ],
    id: 1,
  },
  // { title: 'Planes', link: '/Planes', id: 2 },
  // { title: 'Simula tu cotización', link: '/Simular-cotizacion', id: 3 },
  { title: 'Contacto', link: '/Contacto', id: 4 },
]
const ButtonNavLink = [
  {
    name: "Soy Colaborador",
    url: "https://empleado.audisoft.cl"
  },
  {
    name: "Soy Cliente",
    url: "http://www.clientes.audisoft.cl"
  }
]
function NavBar() {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOpenSubNav, setIsOpenSubNav] = React.useState(false)

  const onItemClick = (menu) => {
    if (menu.subNav) {
      if (isOpenSubNav) {
        setIsOpenSubNav(false)
      } else {
        setIsOpenSubNav(true)
      }
    } else {
      setIsOpenSubNav(false)
      navigate(menu.link)

    }
  }

  return (
    <div className={"mobileNavBar"}>
      <Box bg={'#FFFFFF'} mx="auto">
        <Flex h={"80px"} >
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            color="#adc844"
            backgroundColor="#FFFFFF"
            m="auto"
          />
          <HStack alignItems={'center'}>
            <Box>
              <LinkRouter to="/">
                <Image
                  src={Logo}
                  alt="Audisoft"
                  boxSize={{ base: "50%", sm: "55%", md: '70%', lg: '80%' }}
                  m={"auto"}
                />
              </LinkRouter>
            </Box>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
              {Menus.map((menu) => (
                <Link
                  key={`web-${menu.link}-${menu.id}`}
                  onClick={() => onItemClick(menu)}
                  _active={{
                    textDecoration: '#B2D237 underline',
                  }}
                  p={8}
                  _hover={{
                    textDecoration: '#B2D237 underline',
                  }}
                >
                  <Text
                    fontSize="12px"
                    _hover={{ color: "#B2D237" }}
                  >
                    {menu.title}
                  </Text>
                  {isOpenSubNav && menu.subNav && (
                    <List className={"subNav"}>
                      {menu.subNav?.map((sub) => (
                        <ListItem
                          key={`web-${sub.link}-${sub.id}`}
                          onClick={() => onItemClick(sub)}
                          _hover={{
                            textDecoration: '#B2D237 underline',
                            color: "#B2D237"
                          }}
                        >
                          <Text fontSize="12px">{sub.title}</Text>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Link>
              ))}
            </HStack>
          </HStack>
          {!isOpen && (
            <HStack width="25%">
              {
                ButtonNavLink.map((item, index) => {
                  return (
                    <Button
                      m='auto'
                      borderRadius="16px"
                      backgroundColor="#A5C918"
                      px={6}
                      py={1}
                      key={index}
                      color="white"
                      _hover={{
                        textDecoration: 'none',
                        bg: '#7a9413',
                      }}
                      onClick={() => { window.location.href = item.url }}
                    >
                      <Text fontWeight={"bold"}>{item.name}</Text>
                    </Button>
                  )
                })
              }
            </HStack>
          )}
        </Flex>

        {isOpen ? (
          <Box display={{ md: 'none' }} paddingTop="25%">
            <Grid margin="auto" width="50%" padding="10px" textAlign="center">
              {Menus.map((menu) => (
                <Link
                  key={`mob-${menu.link}-${menu.id}`}
                  onClick={() => onItemClick(menu)}
                  px={2}
                  py={4}
                  rounded={'md'}
                  _active={{
                    textDecoration: '#B2D237 underline',
                    color: "#B2D237"
                  }}
                >
                  <Text fontSize="14px">{menu.title}</Text>
                  {isOpenSubNav && menu.subNav && (
                    <List >
                      {menu.subNav?.map((sub) => (
                        <ListItem
                          key={`mob-${sub.link}-${sub.id}`}
                          onClick={() => onItemClick(sub)}
                          p={2}
                        >
                          <Text fontSize="12px" color="#606060">{sub.title}</Text>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Link>
              ))}
            </Grid>
            <Grid background={'#FCFFEF'} borderRadius="200px 200px 0px 0px">
              <Grid
                margin="auto"
                padding="15%"
                textAlign="center"
              >
                <VStack>
                  {
                    ButtonNavLink.map((item, index) => {
                      return (
                        <Button
                          m='auto'
                          borderRadius="16px"
                          backgroundColor="#A5C918"
                          px={6}
                          py={1}
                          key={index}
                          color="white"
                          _hover={{
                            textDecoration: 'none',
                            bg: '#7a9413',
                          }}
                          onClick={() => { window.location.href = item.url }}
                        >
                          <Text fontWeight={"bold"}>{item.name}</Text>
                        </Button>
                      )
                    })
                  }
                </VStack>
                <Grid paddingTop="60%">
                  <Text color="#A5C918" fontWeight={600}>
                    Síguenos:
                  </Text>
                  <HStack spacing={4} display={{ base: 'flex', md: 'flex' }}>
                    <IconButton
                      icon={<Image src={contacto.instagram.logo_m} alt="instagram_logo" />}
                      as="a"
                      href={contacto.instagram.url}
                      isRound="true"
                      bg="#FCFFEF"
                    />
                    <IconButton
                      icon={<Image src={contacto.facebook.logo_m} alt="facebook_logo" />}
                      as="a"
                      href={contacto.facebook.url}
                      isRound="true"
                      bg="#FCFFEF"
                    />
                    <IconButton
                      icon={<Image src={contacto.youtube.logo_m} alt="youtube_logo" />}
                      as="a"
                      href={contacto.youtube.url}
                      isRound="true"
                      bg="#FCFFEF"
                    />
                  </HStack>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Box>
    </div>
  )
}

export default NavBar
