import React from "react";
import {
  Grid,
  GridItem,
  Image,
  Text,
  useMediaQuery,
  ScaleFade,
} from '@chakra-ui/react';
import parse from 'html-react-parser';

import Contador from "../../../administracion/jsons_images/Productos/Contador/Contador"

export default function Modulos() {
  const [isMediaQuery] = useMediaQuery("(min-width:600px");
  const [showLess, setShowLess] = React.useState({
    0: isMediaQuery ? false : true,
    1: isMediaQuery ? false : true,
    2: isMediaQuery ? false : true,
    3: isMediaQuery ? false : true,
  });

  const handleViewMore = (position) => {
    let newValuve = showLess[position];
    setShowLess({
      ...showLess,
      [position]: !newValuve
    })
  }

  return (
    <Grid
      px={{ lg: "15%", base: "5%" }}
      py="25px"
    >
      <Grid
        display="inline"
        float="center"
        m="auto"
      >
        <Text as="span" fontSize="30px" fontWeight={800}>
          {Contador.modulos.titulo1}
        </Text>
        <Text fontSize="30px" fontWeight={800} pl="5px" pr="30px" as={"mark"} bgColor="#BEE625">
          {Contador.modulos.titulo2}
        </Text>
      </Grid>
      <Grid
        pt="5%"
        templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
        templateRows={{ base: "repeat(7, 1fr)", sm: "repeat(7, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(4, 1fr)" }}
        gap={5}
      >
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={Contador.modulos.contenido[0].imagen} boxSize="200px" m="auto" alt="mod01" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {Contador.modulos.contenido[0].titulo}
          </Text>
          <Text fontSize="12px">
            {showLess[0] ? parse(Contador.modulos.contenido[0].texto.slice(0, 200)) : parse(Contador.modulos.contenido[0].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(0)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ md: 'none' }}
          >
            {showLess[0] ? "..." : "ver menos"}
          </Text>
        </GridItem>

        <GridItem
          colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          display={{ base: "block", md: "none" }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={Contador.modulos.contenido[1].imagen} boxSize="200px" m="auto" alt="mod02" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {Contador.modulos.contenido[1].titulo}
          </Text>
          <Text fontSize="12px" >
            {showLess[1] ? parse(Contador.modulos.contenido[1].texto.slice(0, 200)) : parse(Contador.modulos.contenido[1].texto)}
          </Text>

          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(1)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ md: 'none' }}
          >
            {showLess[1] ? "..." : "ver menos"}
          </Text>

        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          display={{ base: 'none', md: 'block' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={Contador.modulos.contenido[1].imagen} boxSize="200px" m="auto" alt="mod02" />
          </ScaleFade>
        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={Contador.modulos.contenido[2].imagen} boxSize="200px" m="auto" alt="mod03" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {Contador.modulos.contenido[2].titulo}
          </Text>
          <Text fontSize="12px" >
            {showLess[2] ? parse(Contador.modulos.contenido[2].texto.slice(0, 200)) : parse(Contador.modulos.contenido[2].texto)}
          </Text>

          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(2)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ md: 'none' }}
          >
            {showLess[2] ? "..." : "ver menos"}
          </Text>

        </GridItem>

        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
          display={{ base: 'block', md: 'none' }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={Contador.modulos.contenido[3].imagen} boxSize="200px" m="auto" alt="mod04" />
          </ScaleFade>
        </GridItem>
        <GridItem
          colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
          rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Text fontSize="16px" fontWeight="bold" color="#A5C918" >
            {Contador.modulos.contenido[3].titulo}
          </Text>
          <Text fontSize="12px">
            {showLess[3] ? parse(Contador.modulos.contenido[3].texto.slice(0, 200)) : parse(Contador.modulos.contenido[3].texto)}
          </Text>
          <Text
            fontSize="12px"
            style={{ cursor: "pointer" }}
            onClick={() => handleViewMore(3)}
            fontWeight="bold"
            as="span"
            pl="4px"
            display={{ base: 'block', md: 'none' }}
          >
            {showLess[3] ? "..." : "ver menos"}
          </Text>
        </GridItem>
        <GridItem
          display={{ base: 'none', md: 'block' }}
          colSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
          rowSpan={{ base: 0, sm: 0, md: 1, lg: 1 }}
        >
          <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
            <Image src={Contador.modulos.contenido[3].imagen} boxSize="200px" m="auto" alt="mod04" />
          </ScaleFade>
        </GridItem>
      </Grid>
    </Grid >
  );
}
