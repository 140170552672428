import React from "react";
import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player'

export default function ModalWatchVideo({ isOpen, setIsOpen, urlVideo }) {

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={handleClose} />
        <ModalHeader></ModalHeader>
        <ModalBody>
          <ReactPlayer
            playing
            controls
            url={urlVideo}
            width="100%"
            volume={1}
            muted
          />
        </ModalBody>
      </ModalContent>
    </Modal >
  );
}
