import React from 'react';
import {
  Grid,
  GridItem,
  Text,
  Image,
  ScaleFade,
  SimpleGrid,
  Center,
} from '@chakra-ui/react';
import parse from 'html-react-parser';


import conocenos from "../../administracion/jsons_images/Nosotros/Conocenos/conocenos";
import Equipo from './Equipo';
import equipo from '../../administracion/jsons_images/Nosotros/Equipo/equipo';


export default function Conocenos() {

  return (
    <>
      <Grid
        bgImage={conocenos.bg}
        bgSize="cover"
        backgroundRepeat='no-repeat'
      >
        <Grid
          w={{ lg: "60%", base: "80%" }}
          m="auto"
        >
          <Grid
            display="inline"
            float="center"
            pt={{ md: "15%", base: "35%" }}
          >
            <Text as="span" fontSize="30px" fontWeight={800} pl="30px" bgColor="#BEE625">
              {conocenos.titulo1}{" "}
            </Text>
            <Text as="span" fontSize="30px" fontWeight={800}>
              {conocenos.titulo2}
            </Text>
          </Grid>
          <Text as="span" fontSize="30px" fontWeight={800} pl="35px">
            {conocenos.titulo3}
          </Text>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(2, 1fr)" }}
            templateRows={{ base: "repeat(4)", sm: "repeat(4)", md: "repeat(2)", lg: "repeat(2)" }}
            gap={3}
          >
            <GridItem
              colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              m="auto"
            >
              <Image src={conocenos.imagen1} alt="nosotros_01" />
            </GridItem>
            <GridItem
              colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
            >
              <Text fontSize="12px" t>
                {parse(conocenos.contenido1)}
              </Text>
              <Text fontSize="12px" pt="5%">
                {parse(conocenos.contenido2)}
              </Text>
            </GridItem>
            <GridItem
              m="auto"
              w="60%"
              colSpan={1}
              rowSpan={1}
              display={{ base: "flex", md: "none" }}
            >
              <Image src={conocenos.imagen2} alt="nosotros_02" />
            </GridItem>
            <GridItem
              rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              px="5%"
            >
              <Text fontSize="12px" pt="5%">
                {parse(conocenos.contenido3)}
              </Text>
              <Text fontSize="12px" pt="5%">
                {parse(conocenos.contenido4)}
              </Text>
              <Text fontSize="12px" pt="8%">
                {parse(conocenos.contenido5)}
              </Text>
            </GridItem>
            <GridItem
              m="auto"
              w="90%"
              colSpan={1}
              rowSpan={1}
              display={{ base: "none", md: "flex" }}
              overflow={"hidden"}
            >
              <ScaleFade initialScale={0.9} in={true} whileHover={{ scale: 1.1 }}>
                <Image src={conocenos.imagen2} alt="nosotros_02" />
              </ScaleFade>
            </GridItem>
          </Grid>
          <Grid
            py="5%"
            w={{ lg: "60%", base: "80%" }}
            m="auto"
          >
            <Text fontSize="16px" fontWeight="bold" color="#A5C918" textAlign="center">
              {conocenos.contenido6}
            </Text>
          </Grid>
        </Grid >
      </Grid >
      <Grid
        id="Nosotros"
        bgImage={conocenos.bg}
        bgSize="cover"
        backgroundRepeat="no-repeat"
        pt={{ md: '5%', base: '10%' }}
        pb={{ md: '5%', base: '0%' }}
      >
        <Grid w={{ lg: '65%', base: '80%' }} m="auto">
          <Grid display="inline" float="center">
            <Text
              as="span"
              fontSize="30px"
              fontWeight={800}
              pl="30px"
              bgColor="#BEE625"
            >
              {conocenos.titulo1}{' '}
            </Text>
            <Text as="span" fontSize="30px" fontWeight={800}>
              {conocenos.titulo2}
            </Text>
          </Grid>
          <Text as="span" fontSize="30px" fontWeight={800} pl="35px">
            {conocenos.titulo3}
          </Text>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
            templateRows={{
              base: 'repeat(4)',
              sm: 'repeat(4)',
              md: 'repeat(2)',
              lg: 'repeat(2)',
            }}
            gap={3}
          >
            <GridItem
              // colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              // rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              m="auto"
              mx={2}
            >
              <BigImages />
            </GridItem>

            <GridItem
              // colSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              // rowSpan={{ base: 1, sm: 1, md: 1, lg: 1 }}
              mx={2}
            >
              <Text fontSize="13px">{parse(conocenos.contenido1)}</Text>
              <Text fontSize="13px" pt="5%">
                {parse(conocenos.contenido2)}
              </Text>
              <Text fontSize="13px" pt="5%">
                {parse(conocenos.contenido4)}
              </Text>
            </GridItem>
          </Grid>
        </Grid>
        <Equipo />
      </Grid>
    </>
  );
}


const BigImages = () => {
  return (
    // saltar primer elemento
    <SimpleGrid
      columns={2}
      m="auto"
      // spacing={{ base: 2, md: 10 }}
      // w={{ base: '100%', lg: '70%', xl: '75%' }}
      w={{ base: '100%', lg: '100%', xl: '100%' }}
    >
      {equipo.map((eq, index) =>
        // saltar primer elemento
        index < 2 ? (
          <GridItem colSpan={{ base: 1, lg: 1, xl: 1 }} key={index}>
            <Center>
              <Image src={eq.foto} borderRadius={'10%'} w="80%" />
            </Center>
            {/* <div className="wrapper">
              <Center>
                <Image src={eq.foto} borderRadius={'50%'} w="80%" />
              </Center>
              <div className="overlay">
                <div className="content">
                  <Text fontSize={{ base: '8px', md: '10px' }} noOfLines={8}>
                    {eq.descripcion}
                  </Text>
                </div>
              </div>
            </div> */}

            <Text
              textAlign={'center'}
              fontSize={{ base: '16px', md: '20px' }}
              my="8px"
            >
              {eq.nombre}
            </Text>
            <Text
              textAlign={'center'}
              fontSize={{ base: '12px', md: '14px' }}
              mt="2px"
              pb="40px"
              fontWeight={'bold'}
            >
              {eq.cargo}
            </Text>
          </GridItem>
        ) : null
      )}
    </SimpleGrid>
  );
};
