import React from "react";
import {
  Grid,
  Text,
  Input,
  Box,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';

export default function ContactForm({ ...props }) {
  const { formData, setFormData, error } = props

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value })
  }

  return (
    <Grid m="auto" pt="5%">
      <Grid
        display="inline"
        float="center"
        textAlign="center"
        pb="5%"
      >
        <Text as={"span"} fontSize="30px" fontWeight="bold">
          Completa la info
        </Text>
        <Text as={"span"} fontSize="30px" fontWeight="bold" bgColor="#BEE625">
          rmación
        </Text>
        <Text fontSize="16px" >
          Y te contactaremos
        </Text>
      </Grid>
      <Grid
        borderWidth="1px"
        borderColor={"#BEE625"}
        borderRadius={'xl'}
        background="linear-gradient(175deg, rgba(245,245,247,1) 19%, rgba(252,255,239,1) 89%)"
        w="90%"
      >
        <Box
          textAlign="center"
          px="5%"
          py="10%"
        >
          <FormControl
            id="contact-form"
            px={{ base: "5%", md: "0%" }}
          >
            <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Nombre</FormLabel>
            <Input
              aria-label="Nombre"
              onChange={handleChange}
              name="contactName"
              value={formData.contactName}
              bgColor="white"
              border="1px"
              borderRadius="20px"
            />
            <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Apellido</FormLabel>
            <Input
              aria-label="Apellido"
              onChange={handleChange}
              name="contactLastName"
              value={formData.contactLastName}
              bgColor="white"
              border="1px"
              borderRadius="20px"
            />
            <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Email</FormLabel>
            <Input
              aria-label="Email"
              onChange={handleChange}
              name="email"
              value={formData.email}
              bgColor="white"
              border="1px"
              borderRadius="20px"
            />
            <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Número de teléfono móvil</FormLabel>
            <Input
              aria-label="Telefono"
              onChange={handleChange}
              name="phone"
              value={formData.phone}
              bgColor="white"
              border="1px"
              borderRadius="20px"
            />
            <FormLabel fontSize="12px" fontWeight="bold" color="#A5C918">Nombre de la empresa</FormLabel>
            <Input
              aria-label="Nombre Empresa"
              onChange={handleChange}
              name="companyName"
              value={formData.companyName}
              bgColor="white"
              border="1px"
              borderRadius="20px"
            />
            {error &&
              <Text fontSize="12px" color="red.300">Error al enviar el formulario</Text>
            }
          </FormControl>
        </Box>
      </Grid>
    </Grid >
  );
}
