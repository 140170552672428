import React from "react";
import {
  Grid,
  Text,
  Select,
} from '@chakra-ui/react';

export default function Opciones({ ...props }) {
  const { options, title, subtitle, id, answers, setAnswers } = props
  const [finalValue, setFinalValue] = React.useState(options[0]);

  React.useEffect(() => {
    setAnswers({
      ...answers,
      [id]: {
        pregunta: title,
        respuesta: options[0]
      }
    })
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setFinalValue(value)
    setAnswers({
      ...answers,
      [id]: {
        pregunta: title,
        respuesta: value
      }
    })
  }

  return (
    <>
      <Grid m="auto" pt="5%">
        <Text fontSize="16px" fontWeight="bold" m="auto">
          {title}
        </Text>
        {subtitle &&
          <Text fontSize="16px" fontWeight="bold" m="auto">
            {subtitle}
          </Text>
        }
        <Grid m="auto" w={{ md: "60%", base: "80%" }}>
          <Select
            bg="#F5F5F5"
            color="black"
            onChange={handleChange}
            value={finalValue}
          >
            {options.map(option => (
              <option value={option} key={option}> {option}</option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
}
