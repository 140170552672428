import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react'
import parse from 'html-react-parser';

import ModalWatchVideo from "../../components/Shared/ModalWatchVideo"
import slider from "../../administracion/jsons_images/Home/Slider/slider"
import './slider.css'

export default function SliderHome() {
  const history = useNavigate();
  const [urlVideo, setUrlVideo] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [slideIndex, setSlideIndex] = useState(1)
  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (!isOpen) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setSlideIndex((prevIndex) =>
            prevIndex === slider.length ? 1 : prevIndex + 1
          ),
        5000
      );
      return () => {
        resetTimeout();
      };
    }
  }, [isOpen, slideIndex]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  const handleChangePage = (link) => {
    history(link)
  }

  const moveDot = (index) => {
    setSlideIndex(index)
  }

  const handleOpenVideo = (url) => {
    setUrlVideo(url)
    setIsOpen(true)
  }

  function SliderComponent({ slide }) {
    return (
      <Grid
        bgImage={{
          base: slide.bg_m,
          sm: slide.bg_w
        }}
        bgSize={{ base: "cover", sm: 'cover', md: "auto auto", lg: "auto", xl: "100%" }}
        backgroundRepeat='no-repeat'
      >
        <GridItem
          pl={{ base: '13%', lg: '15%' }}
          pt={{ base: '550px', sm: '300px', lg: "200px" }}
        >
          <Grid >
            <Text fontSize="60px" fontWeight="bold" pl="10px">
              {slide.titulo1}
            </Text>
            <Text textAlign="left">
              <span
                style={{
                  fontSize: "50px",
                  fontWeight: "bold",
                  backgroundColor: "#A5C918",
                  pl: "10px"
                }}
              >
                {slide.titulo2}
              </span>
            </Text>
          </Grid>
          <Text w="50%" fontSize="30px" fontWeight={800} pl="10px" noOfLines={2}>
            {parse(slide.subtitulo1)}
          </Text>
          <Grid w={{ base: '80%', lg: '40%' }}>
            <Text fontSize="16px" pl="10px">
              {parse(slide.subtitulo2)}
            </Text>
          </Grid>
          {/* <ButtonGroup pt={5}>
            <Button
              borderRadius="16px"
              backgroundColor="#A5C918"
              color="white"
              onClick={() => handleChangePage('/Planes')}
              _hover={{
                textDecoration: 'none',
                bg: '#7a9413',
              }}
            >
              Ver planes
            </Button>
            <Button
              borderRadius="16px"
              backgroundColor="#A5C918"
              color="white"
              _hover={{
                textDecoration: 'none',
                bg: '#7a9413',
              }}
              onClick={() => handleOpenVideo(slide.video)}
            >
              Ver video
            </Button>
          </ButtonGroup> */}
        </GridItem>
      </Grid>
    )
  }

  return (
    <Grid className="container-slider" >
      <ModalWatchVideo isOpen={isOpen} setIsOpen={setIsOpen} urlVideo={urlVideo} />
      <SliderComponent slide={slider[slideIndex - 1]} />
      <div className="container-dots">
        {slider.map((_, index) => (
          <div
            key={index}
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? 'dot active' : 'dot'}
          ></div>
        ))}
      </div>
    </Grid>
  )
}
